export const LightTheme = {
  color: {
    //Main
    primary: '#612f74',
    primaryLight: '#905ba3',
    primaryDark: '#340348',
    secondary: '#fafafa',
    secondaryLight: '#ffffff',
    secondaryDark: '#c7c7c7',
    gradient1:
      'linear-gradient(90deg, rgba(172,72,148,1) 0%, rgba(228,76,48,1) 100%, rgba(202,9,18,1) 100%)',
    //Background
    backgroundPrimary: '#612f74',
    backgroundPrimaryLight: '#905ba3',
    backgroundPrimaryDark: '#340348',
    backgroundSecondary: '#fafafa',
    backgroundSecondaryLight: '#ffffff',
    backgroundSecondaryDark: '#c7c7c7',
    //Border
    borderColor: '#d9d9d9',
    // Shadow
    shadowOnlyBottom:
      '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    shadowBox:
      '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    cardShadowBox: 'rgba(0, 0, 0, 0.2) 0px 2px 6px',
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    //Text
    textOnPrimaryBackground: '#fff',
    textOnSecondaryBackground: '#303030',
    //Others
    tooltipBackground: '#fafafa',
    scrollBar: '#BEC3C9',
    //Status
    success: '#2dce89',
    info: '#11cdef',
    warning: '#fb6340',
    danger: '#f5365c',
    //Basic
    blue: '#5e72e4',
    indigo: '#5603ad',
    purple: '#8965e0',
    pink: '#f3a4b5',
    red: '#f5365c',
    orange: '#fb6340',
    yellow: '#ffd600',
    green: '#2dce89',
    teal: '#11cdef',
    cyan: '#2bffc6',
    gray: '#f1f2f6',
    white: '#ffffff',
  },
};

export const DarkTheme = {
  color: {
    //Main
    primary: '#612f74',
    primaryLight: '#905ba3',
    primaryDark: '#340348',
    secondary: '#212121',
    secondaryLight: '#484848',
    secondaryDark: '#000000',
    gradient1:
      'linear-gradient(90deg, rgba(172,72,148,1) 0%, rgba(228,76,48,1) 100%, rgba(202,9,18,1) 100%)',
    //Background
    backgroundPrimary: '#612f74',
    backgroundPrimaryLight: '#905ba3',
    backgroundPrimaryDark: '#340348',
    backgroundSecondary: '#212121',
    backgroundSecondaryLight: '#484848',
    backgroundSecondaryDark: '#000000',

    //Shadow
    borderColor: '#d9d9d9',
    shadowOnlyBottom:
      '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    shadowBox:
      '0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
    cardShadowBox: 'rgba(0, 0, 0, 0.2) 0px 2px 6px',
    shadowColor: 'rgba(0, 0, 0, 0.2)',
    //Text
    textOnPrimaryBackground: '#fff',
    textOnSecondaryBackground: '#fff',
    //Others
    tooltipBackground: '#212121',
    //Status
    success: '#2dce89',
    info: '#11cdef',
    warning: '#fb6340',
    danger: '#f5365c',
    //Basic
    blue: '#5e72e4',
    indigo: '#5603ad',
    purple: '#8965e0',
    pink: '#f3a4b5',
    red: '#f5365c',
    orange: '#fb6340',
    yellow: '#ffd600',
    green: '#2dce89',
    teal: '#11cdef',
    cyan: '#2bffc6',
    gray: '#f1f2f6',
    white: '#ffffff',
  },
};
