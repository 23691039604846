import styled from 'styled-components';

export const Container = styled.div`
  height: 60px;
  padding: 0 10px;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const LinkBox = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const LeftItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightItems = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
