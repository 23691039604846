import React, { memo } from 'react';

import { useHistory } from 'react-router-dom';

import { Header, CloseIcon } from './style';

function ProductImages({ theme }) {
  const history = useHistory();

  return (
    <Header borderColor={theme.color.borderColor}>
      <CloseIcon
        onClick={() => history.goBack()}
        color={theme.color.primary}
        hovercolor={theme.color.danger}
      />
    </Header>
  );
}

export default memo(ProductImages);
