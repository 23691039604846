import React, { memo, useState } from 'react';

import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

import { Container, TextBox } from './style';

const possibleDetailsAboutProduct = {
  chip: 'Chip',
  condition: 'Condição',
  memory_ram: 'Memória RAM',
  internal_storage: 'Armazenamento interno',
  processor: 'Processador',
  graphics_processor: 'Processador gráfico',
  memory_card: 'Cartão de memória',
  rear_camera_resolution: 'Resolução da câmera traseira',
  front_camera_resolution: 'Resolução da câmera frontal',
  flash: 'Flash',
  video: 'Video',
  teste: 1,
};

function MoreInfo({ theme, product }) {
  const [moreDetails] = useState(SetMoreDetailsDinamyc());

  function SetMoreDetailsDinamyc() {
    const possibleDetailsKeys = Object.keys(possibleDetailsAboutProduct);

    let additionalDetailsOfProduct = {};

    possibleDetailsKeys.forEach((objectKey, index) => {
      if (product.more_details[objectKey]) {
        let key = possibleDetailsAboutProduct[objectKey];
        let value = product.more_details[objectKey];

        additionalDetailsOfProduct[key] = value;
      }
    });

    const detailsOfProductAsArray = Object.entries(additionalDetailsOfProduct);

    return detailsOfProductAsArray;
  }

  return (
    <Container backgroundColor={theme.color.white}>
      <Title level={4}>Mais informações</Title>
      {moreDetails.map((detail) => (
        <TextBox key={detail[0]}>
          <Text strong style={{ marginRight: 5 }}>
            {`${detail[0]}: `}
          </Text>
          <Text>{detail[1]}</Text>
        </TextBox>
      ))}
    </Container>
  );
}

export default memo(MoreInfo);
