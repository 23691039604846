export default {
  '9a4f1f4': {
    message: 'User is admin',
  },
  SM9D5DCEF: {
    message: 'Browser Successfully Validated',
  },
  SMF84092A: {
    message: 'User Token Successfully Validated',
  },
  SM482973A: {
    message: 'No person with this login was found',
  },
  SM0ADC866: {
    message: 'A person with this login was found',
  },
  SM7AF8780: {
    message: 'A person with this pre login was found',
  },
  SME4478C3: {
    message: 'Login successfully',
  },
  SM034DABA: {
    message: 'Logout successfully',
  },
  SME47D66D: {
    message: 'Requisição recebida pelo Servidor',
  },
};
