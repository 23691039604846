// Environments
import System from '~/environments/System';
// Utils
import { GetModuleNameByHostname } from '~/utils/Hosts';

const moduleName = GetModuleNameByHostname();

export default {
  async Create(data) {
    //? Checks whether you have the name and value field
    if (!data.name || !data.value) {
      return false;
    }
    //? Prepares the expiration field
    let stretchExpire = `expires=${data.expires || System.cookie.expire};`;
    //? Prepare the domain field
    let stretchDomain = `domain=${
      data.domain || System.cookie.domain[moduleName] || 'localhost'
    };`;
    //? Set the cookie
    document.cookie = `${data.name}=${data.value}; ${stretchExpire} ${stretchDomain} path=/;`;
    return true;
  },
  async Get(name) {
    //? Checks whether you have the name field
    if (!name) {
      return false;
    }
    //? Get
    let replace = new RegExp('(^| )' + name + '=([^;]+)');
    let match = document.cookie.match(replace);
    return match ? match[2] : false;
  },
  async Delete(name) {
    //? Checks whether you have the name field
    if (!name) {
      return false;
    }
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    return true;
  },
};
