import React, { memo } from 'react';

import Title from '~/components/typography/title';

import MaxPrice from './components/MaxPrice';
import Condition from './components/Condition';
import Classified from './components/Classified';
import SearchByMaps from './components/SearchByMaps';

import { Container } from './style';

function Filters() {
  return (
    <Container>
      <Title level={4}>Filtros</Title>
      <SearchByMaps />
      <MaxPrice />
      <Condition />
      <Classified />
    </Container>
  );
}

export default memo(Filters);
