import styled from 'styled-components';

import { Select } from 'antd';

const { Option } = Select;

export const MainSelect = styled(Select)`
  width: ${(props) => props.width || '100%'};
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  margin: ${(props) => props.margin};
  border-radius: 8px;
  
  &.ant-select-selector{
    border: none;
  }
  .ant-select-selector{
    border: none;
  }
  &.ant-select-selector{
    border: none;
  }
`;

export const MainOption = styled(Option)`
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`;
