import styled from 'styled-components';

import { Link } from 'react-router-dom';

const MainLink = styled(Link)`
  color: ${(props) => `${props.color} !important`};
  transition: all 0.5s;

  &:hover {
    color: ${(props) => `${props.hovercolor} !important`};
  }
`;

export default MainLink;
