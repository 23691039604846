import React, { useContext } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Standalone Components
import { Container, Item, Image } from './style.js';
//Components
import Text from '~/components/typography/text';

export default function ItemContainer({ image, imageHover, title }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Container>
      <Item hoverBackgroundColor={theme.color.backgroundSecondary}>
        <Image image={image} />
        <Text strong>{title}</Text>
      </Item>
    </Container>
  );
}
