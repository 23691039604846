import styled from 'styled-components';
//Environments
import System from '~/environments/System';

const responsiveBreakpoint = `${System.responsiveBreakpoint}px`;

export const Container = styled.div`
  padding: 0px 5px;
  @media (max-width: ${responsiveBreakpoint}) {
    /* */
  }
`;

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${(props) => `url('${props.image}')` || `url('default')`};
  height: 45px;
  width: 45px;
  @media (max-width: ${responsiveBreakpoint}) {
    /* */
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor || '#fff'};
  }
  @media (max-width: ${responsiveBreakpoint}) {
    /* */
  }
`;
