export default {
  // Label's
  fullNameLabel: {
    original: 'Full name',
    text: 'Nome completo',
  },
  socialNameLabel: {
    original: 'Social Name',
    text: 'Nome Social',
  },
  socialSexLabel: {
    original: 'Social Sex',
    text: 'Sexo Social',
  },
  genderLabel:{
    original: 'Gender',
    text: 'Gênero',
  },
  sexLabel: {
    original: 'Sex',
    text: 'Sexo',
  },
  educationLabel: {
    original: 'Education',
    text: 'Escolaridade',
  },
  maritalStatusLabel: {
    original: 'Marital status',
    text: 'Estado Civil',
  },
  mainContactLabel: {
    original: 'Main Contact',
    text: 'Contato Principal',
  },
  birthLabel: {
    original: 'Birth',
    text: 'Nascimento',
  },
  birthCountryLabel: {
    original: 'Country',
    text: 'País',
  },
  birthStateLabel: {
    original: 'State',
    text: 'Estado',
  },
  birthCityLabel: {
    original: 'City',
    text: 'Cidade',
  },
  // Placeholders
  select: {
    original: 'Select',
    text: 'Selecione',
  },
  socialNamePlaceholder: {
    original: 'How would you like to be called?',
    text: 'Como você gostaria de ser chamado(a)?',
  },
  enterAnEmailOrPhone: {
    original: 'Enter an email or phone',
    text: 'Digite um e-mail ou telefone',
  },
  enterYourFantasyName:{
    original: 'Enter your fantasy name',
    text: 'Digite um nome fantasia',
  },
  enterYourCompanyName: {
    original: 'Enter your company name',
    text: 'Digite sua razão social',
  },
  enterYourBirth: {
    original: 'Enter your birth',
    text: 'Digite seu nascimento',
  },
  enterYourCPFOrCNPJ: {
    original: 'Enter your CPF or CNPJ',
    text: 'Digite seu CPF ou CNPJ',
  },
  enterYourEmail: {
    original: 'Enter your email',
    text: 'Digite seu email',
  },
  enterYourFullName: {
    original: 'Enter your full name',
    text: 'Digite seu nome completo',
  },
  enterYourMotherName: {
    original: 'Enter your mother name',
    text: 'Digite o nome da sua mãe',
  },
  enterYourName: {
    original: 'Enter your name',
    text: 'Digite seu nome',
  },
  enterYourPassword: {
    original: 'Enter your password',
    text: 'Digite sua senha',
  },
  enterYourSex: {
    original: 'Enter your sex',
    text: 'Selecione o sexo',
  },
  enterYourFramework: {
    original: 'Enter your frameworki',
    text: 'Selecione um enquadramento',
  },
  // Invalid Fields
  invalidCPF: {
    original: 'Invalid CPF',
    text: 'CPF inválido',
  },
  invalidCNPJ: {
    original: 'Invalid CNPJ',
    text: 'CNPJ inválido',
  },
  invalidDate: {
    original: 'Invalid date',
    text: 'Data inválida',
  },
  invalidEmail: {
    original: 'Invalid email',
    text: 'Email inválido',
  },
  invalidName: {
    original: 'Invalid name',
    text: 'Este nome está certo mesmo?',
  },
  invalidMotherName: {
    original: 'Invalid mother name',
    text: 'Este nome está certo mesmo?',
  },
  // Alerts
  itIsImportantToEnterAPassword: {
    original: 'It is important to enter a password',
    text: 'É importante informar uma senha',
  },
  theTwoPasswordsAreNotTheSame: {
    original: 'The two passwords are not the same',
    text: 'Por favor, insira um nome válido',
  },
  checkTheFieldsAndTryAgain: {
    original: 'Check the fields and try again',
    text: 'Verifique os campos e tente novamente',
  },
  confirmYourPassword: {
    original: 'Confirm your password',
    text: 'Confirme a sua senha',
  },
  // Required
  companyNameIsRequired: {
    original: 'Company name is required',
    text: 'Por favor, insira uma razão social',
  },
  frameworkIsRequired: {
    original: 'Framework is required',
    text: 'Por favor, insira um enquadramento',
  },
  fantasyNameIsRequired: {
    original: 'Fantasy name is required',
    text: 'Por favor, insira um nome fantasia',
  },
  birthIsRequired: {
    original: 'Birth is required',
    text: 'Por favor, insira um nascimento',
  },
  EmailIsRequired: {
    original: 'Email is required',
    text: 'Por favor, insira um email',
  },
  motherNameIsRequired: {
    original: 'Mother name is required',
    text: 'Por favor, insira o nome da sua mãe',
  },
  nameIsRequired: {
    original: 'Name is required',
    text: 'Por favor, insira um nome válido',
  },
  // Help
  mainContactHelp: {
    original: 'Enter an email or phone with DD',
    text: 'Insira um email ou telefone com DDD',
  },
  socialNameHelp: {
    original: 'How would you like to be called?',
    text: 'Como você gostaria de ser chamado?',
  },
  socialSexHelp: {
    original: 'How would you like to be recognized?',
    text: 'Como você gostaria de ser reconhecido?',
  },
  genderHelp:{
    original: 'How would you like to be identified?',
    text: 'Como você gostaria de ser identificado?',
  },
};
