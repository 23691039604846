import styled from 'styled-components';

import System from '~/environments/System';

export const ProductsContainerBody = styled.div`
  position: relative;
  margin: 5px 0;
  padding: 0 5px;

  height: calc(100vh - 70px);
  overflow-y: auto;
  text-overflow: ellipsis;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    padding: 0 5px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;
