import styled from 'styled-components';

import System from '~/environments/System';

export const GridImages = styled.div`
  width: 100%;
  height: calc(100vh - 50px);

  display: grid;
  grid-template-columns: 100px 85%;
  grid-template-rows: 100%;
  padding: 10px;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    padding: 10px;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
  }
`;

export const ImagesList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  direction: rtl;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;

export const Div = styled.div`
  height: 85px;
  width: 85px;
  margin-bottom: 10px;
  padding: 2px;
  transition: all 0.5s;
  border: 2px solid ${(props) => props.borderColor};
  cursor: pointer;

  &:hover {
    border: 2px solid ${(props) => props.hoverBorderColor};
  }

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    height: 450px;
  }
`;
