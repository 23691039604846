import styled from 'styled-components';

export const Box = styled.div`
  border-radius: 8px;
  background-color: transparent;
  padding: 2px 6px;
  margin-bottom: 10px;
  transition: all 0.5s;
  cursor: pointer;

  & span {
    color: ${(props) => props.hoverBackgroundColor};
  }

  &:hover {
    background-color: ${(props) => props.hoverBackgroundColor};
    opacity: 0.8;
  }

  &:hover span {
    color: ${(props) => props.hoverColor};
  }
`;
