export default {
  loading: {
    original: 'Loading',
    text: 'Carregando',
  },
  almostThere: {
    original: 'Almost there',
    text: 'Quase lá',
  },
  itWasntSupposedToTakeThatLong: {
    original: "It wasn't supposed to take that long",
    text: 'Não era para demorar tanto assim',
  },
  sorryForTheDelay: {
    original: 'Sorry for the delay',
    text: 'Desculpa pela demora',
  },
  tryAgainLater: {
    original: 'Try again later',
    text: 'Tente novamente mais tarde',
  },
  checkingUser: {
    original: 'Checking user',
    text: 'Verificando usuário',
  },
  profilePhotoUpdatedSuccessfully: {
    original: 'Profile photo updated successfully!',
    text: 'Foto de perfil atualizada com sucesso!',
  },
  somethingHappened: {
    original: 'Something happened...',
    text: 'Alguma coisa aconteceu...',
  },
  fetchingYourPhotos: {
    original: 'Fetching your photos...',
    text: 'Buscando suas fotos...',
  },
  didNotChangeYourProfilePhotoForThe1stTime: {
    original: "You haven't changed your profile photo for the first time yet",
    text: 'Você ainda não trocou sua foto de perfil pela 1ª vez',
  },
  invalidFileFormat: {
    original: "Invalid file format",
    text: 'Formato de arquivo inválido',
  },
  QRCodeSuccessfullyChanged: {
    original: "QRCode successfully changed",
    text: 'QRCode alterado com sucesso',
  },
  doYouReallyWantToDeleteThisPhoto: {
    original: "Do you really want to delete this photo?",
    text: 'Deseja realmente apagar essa foto?',
  },
  photoSuccessfullyDeleted: {
    original: "Photo successfully deleted!",
    text: 'Foto apagada com sucesso!',
  },
  updatedProfile: {
    original: "Updated profile!",
    text: 'Perfil atualizado!',
  },
  loadingData: {
    original: "Loading Data...",
    text: 'Carregando Dados...',
  },
  fetchingYourDocuments: {
    original: "Fetching your documents...",
    text: 'Buscando seus documentos...',
  },
  preparingYourDocuments: {
    original: "Preparing your documents...",
    text: 'Preparando seus documentos...',
  },
};
