import React, { memo } from 'react';

import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

import Address from './components/Address';
import Contacts from './components/Contacts';

import { Container } from './style';

function SellerInfo({ theme }) {
  const marginBottom = {
    marginBottom: 5,
  };

  return (
    <Container backgroundColor={theme.color.white}>
      <Title level={4}>Descrição do vendedor</Title>
      <Text style={marginBottom}>
        2009 Honda CG 150 · 1.000 quilômetros rodados{' '}
      </Text>
      <Text style={marginBottom}>
        Vendo Titan 150 ano 2009 valor 3.700 com documento
      </Text>
      <Contacts />
      <Address />
    </Container>
  );
}

export default memo(SellerInfo);
