import React, { memo } from 'react';

import { MainEmpty } from './style';

function AntEmpty({ children, image, imageStyle, description, style }) {
  return (
    <MainEmpty
      description={description}
      image={image}
      imageStyle={imageStyle}
      style={style}
    >
      {children}
    </MainEmpty>
  );
}

export default memo(AntEmpty);
