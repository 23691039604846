import React, { memo } from 'react';

import Text from '~/components/typography/text';

import { Flex, TextBox } from './style';

function Contacts() {
  const marginRight = {
    marginRight: 4,
  };

  return (
    <Flex>
      <Text strong style={{ fontSize: 18 }}>
        Contatos
      </Text>
      <TextBox>
        <Text strong style={marginRight}>
          E-mail:
        </Text>
        <Text>nomedoemail@gmail.com</Text>
      </TextBox>
      <TextBox>
        <Text strong style={marginRight}>
          Whatsapp:
        </Text>
        <Text>9123-3213</Text>
      </TextBox>
      <TextBox>
        <Text strong style={marginRight}>
          Telefone:
        </Text>
        <Text>(95) 55 99963-1599</Text>
      </TextBox>
    </Flex>
  );
}

export default memo(Contacts);
