import API from '~/environments/APIs';

const Donation = {
  GetAll: {
    baseURL: API.socialMe,
    url: '/public/donation/requests',
    method: 'post',
    timeout: '50000',
  },
  GetOne: {
    baseURL: API.socialMe,
    url: '/public/donation/request/donationId',
    method: 'get',
    timeout: '50000',
  },
  GetMy: {
    baseURL: API.socialMe,
    url: '/person/personId/donationRequests',
    method: 'post',
    timeout: '50000',
  },
  GetPending: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/donationRequests',
    method: 'post',
    timeout: '50000',
  },
  Show: {
    baseURL: API.socialMe,
    url: 'person/personId/donationRequest/donationId',
    method: 'get',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: 'person/personId/donationRequest',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: 'person/personId/donationRequest/donationId',
    method: 'patch',
    timeout: '50000',
  },
  File: {
    baseURL: API.socialMe,
    url: 'person/personId/donationRequest/donationId/file',
    method: 'post',
    timeout: '50000',
  },
  Submit: {
    baseURL: API.socialMe,
    url: 'person/personId/donationRequest/donationId/sendForReview',
    method: 'post',
    timeout: '50000',
  },
  AcceptOrRefuse: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/donationRequest/donationId/review',
    method: 'post',
    timeout: '50000',
  },

  Filters: {
    baseURL: API.socialMe,
    url: '/public/donation/filters',
    method: 'post',
    timeout: '50000',
  },

  Offering: {
    Add: {
      baseURL: API.socialMe,
      url: 'person/personId/donationRequest/donationId/offering',
      method: 'post',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: 'person/personId/donationRequest/donationId/offering/offeringId',
      method: 'delete',
      timeout: '50000',
    },
  },
};

export default Donation;
