import React, { createContext, useState, useEffect, useContext } from 'react';
//Libraries
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import LoadingGateway from '~/components/loading/loadingGateway';
import CompletedOperationPage from '~/components/pages/gateway/CompletedOperation';
import PendingOperationPage from '~/components/pages/gateway/PendingOperation';
// Environments
import UrlParameters from '~/environments/UrlParameters';
import System from '~/environments/System';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import { GetModuleNameByHostname } from '~/utils/Hosts';

/* 
  'https://socialme-com-br.s3.amazonaws.com/sm/ppl/102364/wllt/intnt/Rdvxb-rXO6.jpg',
  'https://socialme-com-br.s3.amazonaws.com/sm/ppl/102364/wllt/intnt/-kNiLE5I6r.png',
  'https://socialme-com-br.s3.amazonaws.com/sm/ppl/102364/wllt/intnt/XtsPnbTUE.pdf',
*/

export const GatewayContext = createContext();

const GatewayContextProvider = ({ children }) => {
  const { walletAccount, walletPassword } = useContext(WalletAccountContext);
  const [preparingGateway, setPreparingGateway] = useState(false);
  const [activeMethod, setActiveMethod] = useState('');
  const [intent, setIntent] = useState();
  const [gateways, setGateways] = useState();
  const [contract, setContract] = useState();
  const [amount, setAmount] = useState();
  const [intentInvalid, setIntentInvalid] = useState(false);
  const [completedOperation, setCompletedOperation] = useState();
  const [operationResponse, setOperationResponse] = useState();
  const [formValues, setFormValues] = useState();
  const [files, setFiles] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    async function GetIntent(id) {
      //console.log('id -> ' + id);
      const params = {
        operation: ['Gateway', 'GetIntent'],
        urlParams: {
          intentId: id,
        },
      };
      const response = await SocialMeAPI(params);
      console.log('intent -> ', response.body);
      if (response.success) {
        setGateways(response.body.gateway);
        return setIntent(response.body.intent);
      } else {
        return setIntentInvalid(true);
      }
    }
    let piece1 = query.get(UrlParameters.gatewayPiece1);
    let piece2 = query.get(UrlParameters.gatewayPiece2);
    let piece3 = query.get(UrlParameters.gatewayPiece3);
    //console.log('piece 1 -> ' + piece1);
    //console.log('piece 2 -> ' + piece2);
    //console.log('piece 3 -> ' + piece3);
    if (piece1 && piece2 && piece3) {
      piece1 = piece1.replace(' ', '+');
      piece2 = piece2.replace(' ', '+');
      piece3 = piece3.replace(' ', '+');
      if (!preparingGateway && intent === undefined) {
        if (
          piece1.length === 10 &&
          piece2.length === 11 &&
          piece3.length === 11
        ) {
          setPreparingGateway(true);
          GetIntent(`${piece1}${piece2}${piece3}`);
        } else {
          setIntentInvalid(true);
        }
      }
    } else {
      if (GetModuleNameByHostname() === 'paymentGateway') {
        setIntentInvalid(true);
      }
    }
  }, [query, intent, preparingGateway]);

  useEffect(() => {
    async function GetContract(data) {
      const params = {
        operation: ['Gateway', 'GetContract'],
        urlParams: {
          intentId: intent._id,
        },
        data,
      };
      console.log(data);
      const response = await SocialMeAPI(params);
      console.log(response);
      if (response.success) {
        setContract(response.body.contract);
      }
      return setPreparingGateway(false);
    }
    async function PrepareGateway() {
      for (const key in gateways) {
        if (gateways[key][activeMethod]) {
          return key;
        }
      }
    }
    async function PrepareData() {
      let data = {
        gateway: await PrepareGateway(),
        method: activeMethod,
      };
      GetContract(data);
    }
    if (intent) {
      if (activeMethod) {
        PrepareData();
      }
      return setPreparingGateway(false);
    }
  }, [activeMethod, intent, gateways]);

  async function AddIntent(data) {
    const params = {
      operation: ['Gateway', 'CreatesTheIntent'],
      data,
    };
    console.log(params);
    const response = await SocialMeAPI(params);
    console.log(response);
    if (response.success) {
      let id = response.body._id;
      let piece1 = id.substring(0, 10);
      let piece2 = id.substring(10, 21);
      let piece3 = id.substring(21, 32);
      window.location.replace(
        `https://${System.hostname.paymentGateway}/?${UrlParameters.gatewayPiece1}=${piece1}&${UrlParameters.gatewayPiece2}=${piece2}&${UrlParameters.gatewayPiece3}=${piece3}`
      );
    }
  }

  async function CreatesTheIntent({ agent, agent_id, value }) {
    console.log('Creates the intention');
    setPreparingGateway(true);
    // Origin_System
    const module = GetModuleNameByHostname();
    const origin_system = System.gateway.origins[module];
    if (!origin_system) {
      message.error('Alguma coisa aconteceu...');
      return setPreparingGateway(false);
    }
    // Agent
    if (!agent) {
      message.error('Alguma coisa aconteceu...');
      return setPreparingGateway(false);
    }
    // Value
    if (!value) {
      message.error('Alguma coisa aconteceu...');
      return setPreparingGateway(false);
    }
    // Data
    const data = {
      origin_system: System.gateway.origins[module],
      agent: agent,
      agent_id: agent_id,
      value: value,
      other_parameters: {
        origin_url: window.location.href,
      },
    };
    console.log('data -> ', data);
    AddIntent(data);
  }

  async function ConfirmOperation(data) {
    async function PrepareGateway() {
      for (const key in gateways) {
        if (gateways[key][activeMethod]) {
          return key;
        }
      }
    }
    let treatedData = {
      method: activeMethod,
      gateway: await PrepareGateway(),
      anonymous: false,
      amount: amount,
    };
    if (walletAccount) {
      treatedData.account_number = walletAccount.account_number;
      treatedData.account_vd = walletAccount.account_vd;
      treatedData.agency_number = walletAccount.agency_number;
    }
    if (walletPassword) {
      treatedData.password = walletPassword;
    }
    if (data.other_parameters) {
      treatedData.other_parameters = data.other_parameters;
    }
    if (data.settings) {
      treatedData.settings = data.settings;
    }
    if (data.payer) {
      treatedData.payer = data.payer;
    }
    if (data.address) {
      treatedData.address = data.address;
    }
    if (data.card) {
      treatedData.card = data.card;
    }
    console.log('treatedData -> ', treatedData);
    const params = {
      operation: ['Gateway', 'ConfirmOperation'],
      urlParams: {
        intentId: intent._id,
        contractId: contract._id,
      },
      data: treatedData,
      /*
      data: {
        method: activeMethod,
        gateway: await PrepareGateway(),
        anonymous: false,
        account_number: walletAccount.account_number,
        account_vd: walletAccount.account_vd,
        agency_number: walletAccount.agency_number,
        password: accountPassword,
        payer: {
          firstName: 'Arthur',
          lastName: 'Geronimo',
          mainDocument: '01438074204',
          email: 'arthur@email.com',
          phone: '95981253585',
        },
        address: {
          address: 'Rua Tota Terêncio',
          number: '620',
          complement: 'AP 7',
          neighborhood: 'Jardim Floresta',
          city: 'Boa Vista',
          state: 'Roraima',
          country: 'BR',
          zipCode: '69312058',
        },
        card: {
          number: '4321123412341231',
          holder: 'ARTHUR DE MELO GERONIMO',
          code: '318',
          brand: 'Visa',
          expirationDate: '2024-12-00',
        },
      },
      */
    };
    console.log('params -> ', params.data);
    const response = await SocialMeAPI(params);
    console.log('response -> ', response);
    if (response.success) {
      setOperationResponse(response.body.operation);
      setCompletedOperation(true);
    }

    return response;
  }

  if (intentInvalid) {
    return <div>Invalido</div>;
  }

  if (completedOperation) {
    if (operationResponse.status === 'ACTIVE') {
      return (
        <CompletedOperationPage
          operationResponse={operationResponse}
          intent={intent}
        />
      );
    }
    if (operationResponse.status === 'INACTIVE') {
      return <div>Erro ao concluir a operação</div>;
    }
    if (operationResponse.status === 'PENDING') {
      return <PendingOperationPage operationResponse={operationResponse} />;
    }
    return <div>Nada encontrado</div>;
  }

  return (
    <GatewayContext.Provider
      value={{
        CreatesTheIntent,
        activeMethod,
        setActiveMethod,
        intent,
        setIntent,
        gateways,
        contract,
        amount,
        setAmount,
        ConfirmOperation,
        formValues,
        setFormValues,
        files,
        setFiles,
      }}
    >
      {preparingGateway ? <LoadingGateway /> : children}
    </GatewayContext.Provider>
  );
};

export default GatewayContextProvider;
