import styled from 'styled-components';

const MainLink = styled.a`
  color: ${(props) => `${props.color} !important`};
  transition: all 0.5s;

  &:hover {
    color: ${(props) => `${props.hovercolor} !important`};
  }
`;

export default MainLink;
