import styled from 'styled-components';

import { Input } from 'antd';

export const SearchInput = styled(Input.Search)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  color: ${(props) => props.color};

  .ant-input-affix-wrapper {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    background-color: ${(props) => props.backgroundcolor};
  }

  .ant-input-search-button {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  & button.ant-input-search-button {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
