import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Standalone Components
import { MainOption, MainSelect } from './style';

function AntSelect({
  allowClear,
  autoClearSearchValue,
  autoFocus,
  bordered,
  clearIcon,
  defaultActiveFirstOption,
  defaultOpen,
  defaultValue,
  disabled,
  dropdownClassName,
  dropdownMatchSelectWidth,
  dropdownRender,
  dropdownStyle,
  filterOption,
  getPopupContainer,
  labelInValue,
  listHeight,
  loading,
  maxTagCount,
  maxTagPlaceholder,
  maxTagTextLength,
  menuItemSelectedIcon,
  mode,
  notFoundContent,
  open,
  optionFilterProp,
  optionLabelProp,
  options,
  placeholder,
  removeIcon,
  showArrow,
  showSearch,
  size,
  suffixIcon,
  tagRender,
  tokenSeparators,
  value,
  virtual,
  onBlur,
  onChange,

  onDeselect,
  onDropdownVisibleChange,
  onFocus,
  onInputKeyDown,
  onMouseEnter,
  onMouseLeave,
  onPopupScroll,
  onSearch,
  onSelect,
  noDefaultValue,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);
  const defaultValueAtr = noDefaultValue
    ? {}
    : {
        defaultValue:
          defaultValue || options.length > 0 ? options[0].value : '',
      };
  return (
    <MainSelect
      allowClear={allowClear}
      autoClearSearchValue={autoClearSearchValue}
      autoFocus={autoFocus}
      bordered={bordered}
      clearIcon={clearIcon}
      defaultActiveFirstOption={defaultActiveFirstOption}
      defaultOpen={defaultOpen}
      {...defaultValueAtr}
      disabled={disabled}
      dropdownClassName={dropdownClassName}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownRender={dropdownRender}
      dropdownStyle={dropdownStyle}
      filterOption={filterOption}
      getPopupContainer={getPopupContainer}
      labelInValue={labelInValue}
      listHeight={listHeight}
      loading={loading}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
      maxTagTextLength={maxTagTextLength}
      menuItemSelectedIcon={menuItemSelectedIcon}
      mode={mode}
      notFoundContent={notFoundContent}
      open={open}
      optionFilterProp={optionFilterProp}
      optionLabelProp={optionLabelProp}
      options={options}
      placeholder={placeholder}
      removeIcon={removeIcon}
      showArrow={showArrow}
      showSearch={showSearch}
      size={size ? size : isMobile ? 'large' : 'default'}
      suffixIcon={suffixIcon}
      tagRender={tagRender}
      tokenSeparators={tokenSeparators}
      value={value}
      virtual={virtual}
      onBlur={onBlur}
      onChange={onChange}
      onDeselect={onDeselect}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onFocus={onFocus}
      onInputKeyDown={onInputKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPopupScroll={onPopupScroll}
      onSearch={onSearch}
      onSelect={onSelect}
      backgroundcolor={theme.color.backgroundSecondary}
    >
      {options.map((option, index) => (
        <MainOption
          key={index}
          value={option.value}
          //color={theme.color.textOnSecondaryBackground}
        >
          {option.label}
        </MainOption>
      ))}
    </MainSelect>
  );
}

export default memo(AntSelect);
