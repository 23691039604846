import React, { useEffect, useState, createContext } from 'react';
//Languages
import ptBR from '~/languages/ptBR';
import es from '~/languages/es';
//Environments
import System from '~/environments/System';
import Debug from '~/environments/Debug';
//Utils
import Cookie from '~/utils/Cookie';

const isDebug = Debug.Context.Language;

export const LanguageContext = createContext();

const LanguageContextProvider = ({children}) => {
  const [activeLanguage, setActiveLanguage] = useState('ptBR');
  const [translate, setTranslate] = useState(ptBR);

  useEffect(() => {
    async function VerifyCookie() {
      if (isDebug) console.log('🔍 Searching for language token');
      const UserLanguageCookie = await Cookie.Get(System.cookie.name.language);
      if (UserLanguageCookie) {
        if (isDebug) console.log('  ✔ Language token found');
        if (UserLanguageCookie === 'ptBR') {
          return setActiveLanguage('ptBR');
        }
        if (UserLanguageCookie === 'es') {
          return setActiveLanguage('es');
        }
      } else {
        if (isDebug) console.log('  ❌ Language token not found');
      }
    }
    VerifyCookie();
  }, []);

  useEffect(() => {
    switch (activeLanguage) {
      case 'ptBR':
      default:
        Cookie.Create({
          name: System.cookie.name.language,
          value: 'ptBR',
        });
        return setTranslate(ptBR);
      case 'es':
        Cookie.Create({
          name: System.cookie.name.language,
          value: 'es',
        });
        return setTranslate(es);
    }
  }, [activeLanguage]);

  async function ChangeLanguage(newLanguage) {
    setActiveLanguage(newLanguage);
  }

  return (
    <LanguageContext.Provider
      value={{ translate, ChangeLanguage, activeLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
