export default {
  SMD37241D: {
    message: 'The permission was successfully Updated',
    //Manage.Permission.Update
  },
  SM8F96F0D: {
    message: 'The product/service model was successfully Updated',
    //ProductOrService.Update
  },
  SM1822E0D: {
    message: 'The activity type was successfully Updated',
    //Admin.ActivityType.Edit
  },
  SMD571DD1: {
    message: 'The place was successfully Updated',
    //Admin.Places.Edit
  },
  SME817D6E: {
    message: "The 'donation request' was successfully Updated",
  },
};
