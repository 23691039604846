import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import Navbar from '~/components/navbar/Main';

import Dashboard from './pages/Dashboard';

export default function Page() {
  const navbarItems = [];

  return (
    <div>
      <Navbar navbarItems={navbarItems} />
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/produto/:productId" component={Dashboard} />
        <Route path="*" component={Dashboard}>
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  );
}
