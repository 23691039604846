import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
  border-radius: 8px;
`;

export default Container;
