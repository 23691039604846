import styled from 'styled-components';

import { Collapse } from 'antd';

const { Panel } = Collapse;

export const AntCollapse = styled(Collapse)`
  background-color: ${(props) => {
    if (props.parent) {
      return props.theme.color.primary;
    }
    return props.theme.color.white;
  }};

  border: 1px solid ${(props) => props.theme.color.borderColor};
  border-bottom: none;

  &:last-child {
    border: 1px solid ${(props) => props.theme.color.borderColor};
  }

  .ant-collapse-header {
    color: ${(props) => {
      if (props.parent) {
        return `${props.theme.color.white} !important`;
      }
      return `${props.theme.color.textOnSecondaryBackground} !important`;
    }};
  }

  .ant-collapse-content-box,
  .ant-collapse-content-active {
    padding: 0 !important;
    background-color: white !important;
  }

  .ant-collapse-item {
    border: none;
  }
`;

export const AntPanel = styled(Panel)``;

export const TextBox = styled.div`
  cursor: pointer;
  padding: 3px 40px;
`;
