import React, { memo } from 'react';

import Text from '~/components/typography/text';
import Title from '~/components/typography/title';

import { Container } from './style';

import { WalletFormatConversion } from '~/utils/Transformations';

function BasicProductInfo({ theme, product }) {
  return (
    <Container backgroundColor={theme.color.white}>
      <Title level={3}>{product.nameForSell}</Title>
      <Text strong>{WalletFormatConversion(product.price)}</Text>
      <Text kind="secondary">{`${product.parentCategory} - ${product.childCategory}`}</Text>
      <Text kind="secondary">{`A venda em ${product.city}, ${product.federalState}`}</Text>
    </Container>
  );
}

export default memo(BasicProductInfo);
