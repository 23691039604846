import React, { memo } from 'react';

import Text from '~/components/typography/text';

import { Flex, TextBox } from './style';

function Address() {
  const marginRight = {
    marginRight: 4,
  };

  return (
    <Flex>
      <Text strong style={{ fontSize: 18 }}>
        Endereço
      </Text>
      <TextBox>
        <Text strong style={marginRight}>
          Rua:
        </Text>
        <Text>Sizenando Carmo Cavalcante</Text>
      </TextBox>
      <TextBox>
        <Text strong style={marginRight}>
          Bairro:
        </Text>
        <Text>Jardim Floresta</Text>
      </TextBox>

      <TextBox>
        <Text strong style={marginRight}>
          Número:
        </Text>
        <Text>120</Text>
      </TextBox>
      <TextBox>
        <Text strong style={marginRight}>
          CEP:
        </Text>
        <Text>69312-098</Text>
      </TextBox>
    </Flex>
  );
}

export default memo(Address);
