import React, { useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import Divider from '~/components/divider';

import Categories from './components/Categories';
import Filters from './components/Filters';
import Search from './components/Search';

import { FilterContainerBody } from './style';

function FilterContainer() {
  const { theme } = useContext(ThemeContext);

  return (
    <FilterContainerBody
      scrollBarThumbColor={theme.color.scrollBar}
      scrollBarColor={theme.color.white}
      boxShadow={theme.color.cardShadowBox}
      backgroundColor={theme.color.white}
    >
      <Search />
      <Divider style={{ borderColor: theme.color.white }} />
      <Filters theme={theme} />
      <Divider style={{ borderColor: theme.color.white }} />
      <Categories />
    </FilterContainerBody>
  );
}

export default FilterContainer;
