import React, { memo, useState } from 'react';

import { BlurImage, Image } from '~/components/image';

import { GridImages, ImagesList, Div } from './style';

function ProductImages({ product, theme, isMobile }) {
  const [avatar, setAvatar] = useState(product.avatar);

  function HandleOnHover(image) {
    setAvatar(image);
  }

  return (
    <GridImages>
      <ImagesList
        scrollBarColor={theme.color.white}
        scrollBarThumbColor={theme.color.scrollBar}
      >
        {product.images.map((image) => (
          <Div
            key={image}
            onMouseEnter={() => HandleOnHover(image)}
            hoverBorderColor={theme.color.primary}
            borderColor={
              avatar === image ? theme.color.primary : theme.color.white
            }
          >
            <Image size={isMobile && 'cover'} src={image} />
          </Div>
        ))}
      </ImagesList>

      {!isMobile && <BlurImage src={avatar} />}
    </GridImages>
  );
}

export default memo(ProductImages);
