import React, { memo } from 'react';
// Standalone Components
import { MainAffix } from './style';

function AntAffix({
  offsetBottom,
  target,
  onChange,
  offsetTop,
  children,
  style,
}) {
  return (
    <MainAffix
      offsetBottom={offsetBottom}
      target={target}
      offsetTop={offsetTop}
      onChange={onChange}
      style={style}
    >
      {children}
    </MainAffix>
  );
}

export default memo(AntAffix);
