import API from '~/environments/APIs';

const Profile = {
  Get: {
    baseURL: API.socialMe,
    url: '/person/personId',
    method: 'get',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/person/personId',
    method: 'put',
    timeout: '50000',
  },
  Address: {
    Add: {
      baseURL: API.socialMe,
      url: '/person/personId/address',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/person/personId/address/addressId',
      method: 'patch',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/person/personId/address/addressId',
      method: 'delete',
      timeout: '50000',
    },
  },
  Avatar: {
    Change: {
      baseURL: API.socialMe,
      url: '/person/personId/file/avatar',
      method: 'post',
      timeout: '50000',
    },
    GetAll: {
      baseURL: API.socialMe,
      url: '/person/personId/file/avatars',
      method: 'get',
      timeout: '50000',
    },
  },
  QRCode: {
    Change: {
      baseURL: API.socialMe,
      url: '/person/personId/QRCode',
      method: 'patch',
      timeout: '50000',
    },
  },
  Document: {
    Upload: {
      baseURL: API.socialMe,
      url: '/person/personId/file/document/documentName',
      method: 'post',
      timeout: '50000',
    },
  },
  Phone: {
    Add: {
      baseURL: API.socialMe,
      url: '/person/personId/phone',
      method: 'post',
      timeout: '50000',
    },
    Edit: {
      baseURL: API.socialMe,
      url: '/person/personId/phone/phoneId',
      method: 'patch',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/person/personId/phone/phoneId',
      method: 'delete',
      timeout: '50000',
    },
  },
  Relantionship: {
    Kinships: {
      baseURL: API.socialMe,
      url: '/person/personId/kinships',
      method: 'post',
      timeout: '50000',
    },
    GetAll: {
      baseURL: API.socialMe,
      url: '/person/personId/relationships',
      method: 'post',
      timeout: '50000',
    },
    Pending: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship/requests',
      method: 'post',
      timeout: '50000',
    },
    Add: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship',
      method: 'post',
      timeout: '50000',
    },
    Delete: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship/relationshipId/remove',
      method: 'delete',
      timeout: '50000',
    },
    Reject: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship/relationshipId/reject',
      method: 'delete',
      timeout: '50000',
    },
    Approve: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship/relationshipId/approve',
      method: 'put',
      timeout: '50000',
    },
    Cancel: {
      baseURL: API.socialMe,
      url: '/person/personId/relationship/relationshipId/cancel',
      method: 'delete',
      timeout: '50000',
    },
  },
};

export default Profile;
