import React, { useContext, memo } from 'react';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Libraries
import { MainButton } from './style';

function AntButton({
  children,
  disabled,
  htmlType,
  icon,
  loading,
  onClick,
  size,
  style,
  text,
  type,
  shape,
}) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <MainButton
      shape={shape}
      style={style}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      icon={icon}
      htmlType={htmlType}
      type={type || 'default'}
      size={isMobile ? 'large' : size ? size : 'default'}
    >
      {children || text}
    </MainButton>
  );
}

export default memo(AntButton);
