import styled from 'styled-components';
//Environments
import System from '~/environments/System';

const responsiveBreakpoint = `${System.responsiveBreakpoint}px`;

export const Container = styled.div`
  width: 100%;
  background-color: ${(style) => style.backgroundColor || '#fff'};
  padding: 20px 20px 0px 20px;
  border: 1px solid ${(style) => style.borderColor || 'rgba(0, 0, 0, 0.2)'};
  box-shadow: ${(style) =>
    style.boxShadow ||
    '0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15)'};
  height: 100%;
  overflow-x: hidden;
  position: relative;
  @media (max-width: ${responsiveBreakpoint}) {
    /* */
  }
`;

export const Link = styled.a``;
