import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import CategoryHeader from './components/CategoryHeader';
import CommercialCard from './components/CommercialCard';
import ProductListItems from './components/ProductListItems';

import { ProductsContainerBody } from './style';

function ProductsContainer() {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <ProductsContainerBody
        scrollBarThumbColor={theme.color.scrollBar}
        scrollBarColor={theme.color.white}
      >
        <CommercialCard
          title="Compre ou venda produtos ou serviços localmente."
          description="Caso deseja vender algum produto ou serviço, clique no botão abaixo."
        />

        <CategoryHeader />

        <ProductListItems />
      </ProductsContainerBody>
    </>
  );
}

export default memo(ProductsContainer);
