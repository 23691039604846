import React, { memo, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Button from '~/components/button';

import Title from '~/components/typography/title';
import Card from '~/components/card';

import categories from '~/pages/Marketplace/categories';

import { SetNewQueryParameterWithoutRepeat } from '~/utils/Transformations';

function CategoryHeader() {
  const [urlCategory, setUrlCategory] = useState('');
  const [childOfParentCategory, setChildOfParentCategory] = useState([]);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    function GetQueryParameter(query) {
      const parameter = new URLSearchParams(location.search).get(query);

      return parameter || '';
    }

    const categoryParent = GetQueryParameter('categoryParent');
    const categoryChildren = GetQueryParameter('categoryChildren');

    for (const key in categories) {
      const categoryName = categories[key].name;

      if (categoryName === categoryParent) {
        const categoryChild = Object.entries(categories[key].children);

        setUrlCategory({
          parentName: categoryParent,
          parentDisplayName: categories[key].displayName,
          children: categoryChildren,
        });

        setChildOfParentCategory(categoryChild);

        break;
      }
    }
  }, [location.search]);

  function GetQueryParameter(query) {
    const parameter = new URLSearchParams(location.search).get(query);

    return parameter || '';
  }

  return (
    GetQueryParameter('categoryParent') !== '' && (
      <Card shadow="true" style={{ marginBottom: 5 }}>
        <Title level={3}>{urlCategory.parentDisplayName}</Title>
        {childOfParentCategory.map((childCategorie) => {
          return (
            <Button
              key={childCategorie[1].name}
              onClick={() => {
                SetNewQueryParameterWithoutRepeat(
                  [
                    {
                      name: 'categoryParent',
                      value: urlCategory.parent,
                    },
                    {
                      name: 'categoryChildren',
                      value: childCategorie[1].name,
                    },
                  ],
                  history,
                  location
                );
              }}
              style={{ margin: '0 5px 5px 0' }}
            >
              {childCategorie[1].displayName}
            </Button>
          );
        })}
      </Card>
    )
  );
}

export default memo(CategoryHeader);
