import React, { useContext, memo } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { LanguageContext } from '~/contexts/LanguageContext';
// Standalone components
import { LoadContainer, Spinner, LoadingIcon } from './style';

function AntLoading({ height, text }) {
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);
  return (
    <LoadContainer height={height || '300px'}>
      <Spinner
        style={{ color: theme.color.primary }}
        color={theme.color.primary}
        indicator={<LoadingIcon color={theme.color.primary} spin />}
        tip={text || translate.messages.loading.text}
      />
    </LoadContainer>
  );
}

export default memo(AntLoading);
