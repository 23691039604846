import styled from 'styled-components';

import System from '~/environments/System';

export const Container = styled.div`
  width: 70%;
  height: 102vh;
  background-color: ${(props) => props.backgroundColor};
  border-right: 2px solid ${(props) => props.borderColor};
  padding-bottom: 15px;

  @media (max-width: ${`${System.TabletBreakpoint}px`}) {
    width: 65%;
  }

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    width: 100%;
  }
`;
