import styled from 'styled-components';

import System from '~/environments/System';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  min-width: 100vw;
  margin-top: -60px;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    height: 100vh;
    overflow-y: auto;
  }
`;
