export default {
    // Sidebar
    basicInformation: {
        original: 'Basic information',
        text: 'Informações básicas',
    },
    documents: {
        original: 'Documents',
        text: 'Documentos',
    },
    contacts: {
        original: 'Contacts',
        text: 'Contatos',
    },
    adresses: {
        original: 'Adresses',
        text: 'Endereços',
    },
    timeline: {
        original: 'Timeline',
        text: 'Linha do Tempo',
    },
    relationships: {
        original: 'Relationships',
        text: 'Relacionamentos',
    },
    family: {
        original: 'Family',
        text: 'Família',
    },
    weaknessesAndDeficiencies: {
        original: 'Weaknesses and deficiencies',
        text: 'Debilitações e deficiências',
    },
    workAndRemuneration: {
        original: 'Work and remuneration',
        text: 'Trabalho e remuneração',
    },
    bindings: {
        original: 'Bindings',
        text: 'Vinculações',
    },
    settings: {
        original: 'Settings',
        text: 'Configurações',
    },
    // Basic Information
    clickHereToChangeYourAvatar: {
        original: 'Click here to change your avatar',
        text: 'Clique aqui para trocar seu avatar',
    },
    updatingProfilePhoto: {
        original: 'Updating profile photo',
        text: 'Atualizando foto de perfil',
    },
    changeProfilePhoto: {
        original: 'Change profile photo',
        text: 'Trocar foto de perfil',
    },
    newPicture: {
        original: 'New picture',
        text: 'Nova foto',
    },
    profilePercentage: {
        original: 'Profile percentage',
        text: 'Porcentagem do perfil',
    },
    updateQRCode: {
        original: 'Update QRCode',
        text: 'Atualizar QRCode',
    },
    printMeIdentity: {
        original: 'Print Me Identity',
        text: 'Imprimir Identidade Me',
    },
    basic: {
        original: 'Basic',
        text: 'Básico',
    },
    birth: {
        original: 'Birth',
        text: 'Nascimento',
    },
    socialIdentity: {
        original: 'Social Identity',
        text: 'Identidade Social',
    },
    // Documents
    documentsTitle: {
        original: 'Documents',
        text: 'Documentos',
    },
};
