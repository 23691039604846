import API from '~/environments/APIs';

const Product = {
  Search: {
    baseURL: API.socialMe,
    url: '/person/personId/offerings',
    method: 'post',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/offering',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/offering/offeringId',
    method: 'put',
    timeout: '50000',
  },
  Delete: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/offering/offeringId',
    method: 'delete',
    timeout: '50000',
  },
  // manage/person/personId/offering/offeringId
  /* 
    tag: {
      add: []
      sub: []
    }
  */
};

export default Product;
