import styled from 'styled-components';

import System from '~/environments/System';

export const Body = styled.div`
  overflow-y: none;
  display: grid;
  grid-template-columns: 28% 72%;
  grid-template-rows: 100%;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    padding: 0;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
  }
`;

export const Tag = styled.div`
  height: 40px;
  width: 110px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: ${(props) => props.backgroundColor};
  border-left: none;
  display: flex;

  justify-content: space-around;
  align-items: center;
`;
