import Auth from './auth';
import Donation from './donation';
import Person from './person';
import Product from './product';
import Profile from './profile';
import System from './system';
import SystemOperations from './systemOperations';
import Geo from './geo';
import Gateway from './gateway';

const Request = {
  Auth,
  Donation,
  Person,
  Product,
  Profile,
  System,
  SystemOperations,
  Geo,
  Gateway,
};

export default Request;
