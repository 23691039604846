import React, { memo } from 'react';

import { Link, useLocation } from 'react-router-dom';

import Text from '~/components/typography/text';

import { Container, LinkBox } from './style';

function MobileDrawer({ propertys }) {
  const { navbarItems, setCloseDrawer } = propertys;
  const { pathname } = useLocation();

  return (
    <Container>
      {navbarItems.map((navbarItem) => (
        <LinkBox key={navbarItem.path}>
          <Link onClick={() => setCloseDrawer(true)} to={navbarItem.path}>
            <Text
              style={{ fontSize: 16 }}
              kind={pathname === navbarItem.path ? 'primary' : 'secondary'}
            >
              {navbarItem.title}
            </Text>
          </Link>
        </LinkBox>
      ))}
    </Container>
  );
}

export default memo(MobileDrawer);
