import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  background-color: red;
  padding: 0 10px;
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: 768px) {
    height: auto;
    padding: 6px 10px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const ImageBox = styled.div`
  margin-right: 15px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  width: 60px;
  height: 50px;
`;

export const LinkBox = styled.div`
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
`;

export const LeftItems = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const RightItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
