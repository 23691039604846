import styled from 'styled-components';

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
`;

export const TextBox = styled.div`
  width: 100%;
  display: flex;
`;
