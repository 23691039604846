import styled from 'styled-components';

import { MapContainer } from 'react-leaflet';

const Map = styled(MapContainer)`
  width: 100%;
  height: 100%;
`;

export default Map;
