import React from 'react';

import { Circle } from 'react-leaflet';

import Map from '~/components/maps';

import Container from './style';

export default function MapContainer({ coordinates, maxDistancie }) {
  return (
    <Container>
      <Map coordinates={coordinates} zoom={16 - maxDistancie}>
        <Circle center={coordinates} radius={maxDistancie * 1000} />
      </Map>
    </Container>
  );
}
