import styled from 'styled-components';

import { IoMdSearch } from 'react-icons/io';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchIcon = styled(IoMdSearch)`
  font-size: 22px;
`;
