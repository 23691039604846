import React, { memo } from 'react';
// Standalone Components
import Image from './style';

function MainImage({ src, onClick, width, height, style, type, alt }) {
  return (
    <Image
      onClick={onClick}
      height={height || '100%'}
      width={width || '100%'}
      style={style}
      src={src}
      alt={alt}
    />
  );
}

export default memo(MainImage);
