import API from '~/environments/APIs';

const Gateway = {
  CreatesTheIntent: {
    baseURL: API.socialMe,
    url: '/dw/operation/intent',
    method: 'post',
    timeout: '50000',
  },
};

export default Gateway;
