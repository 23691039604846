export default {
  checkTheFieldsAndTryAgain: {
    original: 'Check the fields and try again',
    text: 'Verifique os campos e tente novamente',
  },
  enterYourPasswordToAccessTheSystem: {
    original: 'Enter your password to access the system',
    text: 'Insira sua senha para acessar o sistema',
  },
  invalidLogin: {
    original: 'Invalid login',
    text: 'Login inválido',
  },
  loginSuccessfully: {
    original: 'Login successfully',
    text: 'Login realizado com sucesso',
  },
  notMyCPF: {
    original: 'Not my CPF',
    text: 'Não é o meu CPF',
  },
  notMyCNPJ: {
    original: 'Not my CNPJ',
    text: 'Não é o meu CNPJ',
  },
  physicalPersonRegister: {
    original: 'Physical person register',
    text: 'Registro de pessoa física',
  },
  legalPersonRegister: {
    original: 'Legal person register',
    text: 'Registro de pessoa jurídica',
  },
  verification: {
    original: 'Verification',
    text: 'Verificação',
  },
  verificationDescription: {
    original: 'VerificationDescription',
    text:
      'Insira seu CPF ou CNPJ no campo abaixo para identificarmos se você ou a sua instituição já se encontra cadastrada no nosso banco de dados.',
  },
  welcome: {
    original: 'Welcome',
    text: 'Bem vindo!',
  },
  youMustAcceptTheTerms:{
    original: 'You must accept the terms',
    text: 'Você deve aceitar os termos',
  },
  iHaveReadAndAgreeToThe:{
    original: 'I have read and agree to the',
    text: 'Eu li e concordo com os',
  },
  termsOfUse:{
    original: 'terms of use',
    text: 'termos de uso',
  },
  iDoNotAgreeWithTheTerms:{
    original: 'I do not agree with the terms',
    text: 'Não concordo com os termos',
  },
  iAgreeWithTheTerms:{
    original: 'I agree with the terms',
    text: 'Concordo com os termos',
  },
  registrationSuccessful: {
    original: 'Registration successful',
    text: 'Registro feito com sucesso',
  },
  theCPFIsAlreadyRegistered: {
    original: 'The CPF is already registered',
    text: 'O CPF já se encontra registrado',
  },
  theCNPJIsAlreadyRegistered: {
    original: 'The CNPJ is already registered',
    text: 'O CNPJ já se encontra registrado',
  },
};
