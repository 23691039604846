import React, { memo, useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Row } from 'antd';

import phoneA from '~/assets/images/phones/cel-1.jpg';
import phoneB from '~/assets/images/phones/cel-2.jpg';
import phoneC from '~/assets/images/phones/cel-3.jpg';
import phoneD from '~/assets/images/phones/cel-4.jpg';
import phoneE from '~/assets/images/phones/cel-5.jpg';
import phoneF from '~/assets/images/phones/cel-6.jpg';
import phoneG from '~/assets/images/phones/cel-7.jpg';
import phoneH from '~/assets/images/phones/cel-8.png';
import phoneI from '~/assets/images/phones/cel-9.jpg';
import phoneJ from '~/assets/images/phones/cel-10.jpg';
import phoneK from '~/assets/images/phones/cel-11.jpg';
import phoneL from '~/assets/images/phones/cel-12.jpg';

import Text from '~/components/typography/text';
import Loading from '~/components/loading';
import Empty from '~/components/empty';
import { Image } from '~/components/image';

import { WalletFormatConversion } from '~/utils/Transformations';

import { Column, AntCard } from './style';

function ProductListItems() {
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  function SeeProductDetails(product) {
    const url = `/produto/${product.key}`;

    history.push(url);
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [location.search]);

  const products = [
    {
      key: 1,
      images: [
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c0.106.958.958a/s261x260/142729666_234425474888106_7057293188200220815_o.jpg?_nc_cat=108&ccb=2&_nc_sid=3b2858&_nc_ohc=FDjbuZXKDaIAX9Ix3n4&_nc_ht=scontent.fbvb3-1.fna&tp=28&oh=913b3e8717bd715fcfcb31b216d7b8c9&oe=60427D76',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c0.29.261.261a/p261x260/145744290_423792318890560_2478614599376569257_o.jpg?_nc_cat=104&ccb=2&_nc_sid=3b2858&_nc_ohc=uf6fFnDu-OIAX8XNtUg&_nc_ht=scontent.fbvb3-1.fna&tp=27&oh=3bf789fa591f232cb7d2e248fe896218&oe=6041F6FD',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c0.139.540.540a/s261x260/144735932_1788353581324569_3572832428871168295_n.jpg?_nc_cat=101&ccb=2&_nc_sid=3b2858&_nc_ohc=0Lf-appCqmcAX9hEHHt&_nc_ht=scontent.fbvb3-1.fna&tp=28&oh=4c1a2845b9baf1b36cd168f0fe3394ec&oe=603F478D',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c0.277.1080.1080a/s261x260/145887559_901562790617014_5548819040024166964_o.jpg?_nc_cat=103&ccb=2&_nc_sid=3b2858&_nc_ohc=F_JzoeGkph4AX9mVVGD&_nc_ht=scontent.fbvb3-1.fna&tp=28&oh=9243c65a07a2eb2cc797180b1a45dca2&oe=603F1B36',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c41.0.260.260a/p261x260/145019824_2792521777630722_3711061683858182936_n.jpg?_nc_cat=105&ccb=2&_nc_sid=843cd7&_nc_ohc=d4qRaKBb6CcAX9Slmj6&_nc_ht=scontent.fbvb3-1.fna&tp=27&oh=eb8386ba7e4cf893d43718499edfa2f5&oe=6041D4D9',
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneA,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 2,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneC,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 3,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c0.277.1080.1080a/s261x260/145887559_901562790617014_5548819040024166964_o.jpg?_nc_cat=103&ccb=2&_nc_sid=3b2858&_nc_ohc=F_JzoeGkph4AX9mVVGD&_nc_ht=scontent.fbvb3-1.fna&tp=28&oh=9243c65a07a2eb2cc797180b1a45dca2&oe=603F1B36',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c41.0.260.260a/p261x260/145019824_2792521777630722_3711061683858182936_n.jpg?_nc_cat=105&ccb=2&_nc_sid=843cd7&_nc_ohc=d4qRaKBb6CcAX9Slmj6&_nc_ht=scontent.fbvb3-1.fna&tp=27&oh=eb8386ba7e4cf893d43718499edfa2f5&oe=6041D4D9',
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
      ],
      avatar: phoneB,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 4,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144959673_1670573666448894_3858971969281402969_n.jpg?_nc_cat=101&ccb=2&_nc_sid=3b2858&_nc_ohc=Auq5C_i3pPQAX88ItB3&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=f4c198e6fb4185a52dd0486bf7ee1310&oe=604660CF',
      ],
      avatar: phoneD,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 5,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.29.261.261a/p261x260/146129398_2741552902841560_1277240157557282959_o.jpg?_nc_cat=111&ccb=2&_nc_sid=3b2858&_nc_ohc=IZi3K_OXjJoAX9TEd42&_nc_ht=scontent.fbvb2-1.fna&tp=27&oh=8a5c89cdaaf8f73cf635f014a2df028e&oe=6045AFC7',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneE,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 6,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://scontent.fbvb3-1.fna.fbcdn.net/v/t1.0-0/c41.0.260.260a/p261x260/145019824_2792521777630722_3711061683858182936_n.jpg?_nc_cat=105&ccb=2&_nc_sid=843cd7&_nc_ohc=d4qRaKBb6CcAX9Slmj6&_nc_ht=scontent.fbvb3-1.fna&tp=27&oh=eb8386ba7e4cf893d43718499edfa2f5&oe=6041D4D9',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneF,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 7,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.106.960.960a/s261x260/139012173_1858956367576738_3705534819916180093_o.jpg?_nc_cat=104&ccb=2&_nc_sid=3b2858&_nc_ohc=ofIpJRvaVJYAX_iPM_K&_nc_oc=AQnVPIzuv2vt8wDkG8-mQqFTUjkuY-c3aMNKG2NO-cNSsW2zKEczRVX0-hSev80BraQ&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=04af4f3e630f01b243a3bf5bec87db41&oe=604436BD',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneK,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 8,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.83.750.750a/s261x260/148140850_177999324100735_96143050540783281_o.jpg?_nc_cat=111&ccb=2&_nc_sid=3b2858&_nc_ohc=LMOgU7y5thkAX-GYl8b&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=4cd9db8d2e803e99504aaf9b7bb9695c&oe=60463067',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneJ,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 9,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneL,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 10,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://www.google.com/imgres?imgurl=https%3A%2F%2Fi.zst.com.br%2Fimages%2Fos-10-celulares-mais-vendidos-em-agosto-de-2019-samsung-domina-ranking-photo900260016-44-b-2c.jpg&imgrefurl=https%3A%2F%2Fwww.zoom.com.br%2Fcelular%2Fdeumzoom%2Fcelulares-mais-vendidos-agosto-2019&tbnid=l1igePT6tTx35M&vet=12ahUKEwjchZ-F89juAhXHDrkGHcKSAakQMygFegUIARC6Ag..i&docid=OWVGTuWH6oVxKM&w=728&h=432&q=Celulares&ved=2ahUKEwjchZ-F89juAhXHDrkGHcKSAakQMygFegUIARC6Ag',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneI,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 11,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.238.720.720a/s261x260/143965523_1048762985629980_8389769909357318169_o.jpg?_nc_cat=101&ccb=2&_nc_sid=3b2858&_nc_ohc=S3udEzfnMi0AX_VF-SG&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=f5cd7b4a188cd2763171a8d8bf349048&oe=60463E73',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
      ],
      avatar: phoneH,
      price: '480000000',
      nameForSell: 'J7 PRO funcionando perfeitamente',
      in_stock: true,
      description: 'Vendo celular G6 play 32:GB de memória interna',
      parentCategory: 'Eletrônico',
      childCategory: 'Celulares Samsung',
      city: 'Boa vista',
      federalState: 'RR',
      more_details: {
        chip: 'Samsung Exynos 7884 Octa',
        condition: 'novo',
        memory_ram: '4 GB',
        internal_storage: '32 GB',
        processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
        graphics_processor: 'ARM Mali-G71 MP2',
        memory_card: 'MicroSD de até 512 GB',
        rear_camera_resolution: '13 MP',
        front_camera_resolution: '5 MP',
        flash: 'LED',
        video: '1080p 30fps',
      },
    },
    {
      key: 12,
      images: [
        'https://scontent.fsdu12-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144409982_1924464871039321_5654767515239456851_n.jpg?_nc_cat=110&ccb=2&_nc_sid=3b2858&_nc_ohc=LGvLwP8xtmMAX-ZVXwU&_nc_ht=scontent.fsdu12-1.fna&tp=28&oh=c938b1a11ffd75a98c92fa87f321bf3f&oe=603C2EF3',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.79.720.720a/s261x260/144186258_257726262454808_817207357493958833_n.jpg?_nc_cat=102&ccb=2&_nc_sid=843cd7&_nc_ohc=Ma2VmHKgmYMAX-_iVK2&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=5fd2c0137b413ae554cfc73f3da20ae3&oe=6041F63A',
        'https://images-americanas.b2w.io/spacey/2019/11/06/g8plus.jpg',
        'https://a-static.mlcdn.com.br/1500x1500/celular-samsung-galaxy-a21s-azul-64gb/shoptem2/51371/0adaab5b98fd54db5d76d715775b8211.jpg',
        'https://scontent.fbvb2-1.fna.fbcdn.net/v/t1.0-0/c0.185.720.720a/s261x260/142512013_1061336897712775_4387935641810456784_o.jpg?_nc_cat=103&ccb=2&_nc_sid=3b2858&_nc_ohc=gHL_ocgfZ94AX_1jVBA&_nc_ht=scontent.fbvb2-1.fna&tp=28&oh=7e58a2f95b201503cdebee7ccff4a4d8&oe=60449FC7',
      ],
      avatar: phoneG,
      price: '480000000',
      description: 'Vendo celular G6 play 32:GB de memória interna',
      city: 'Boa vista',
      federalState: 'RR',
    },
  ];

  return loading ? (
    <Loading text="Carregando novos produtos" />
  ) : products.length > 0 ? (
    <Row>
      {products.map((product) => (
        <Column
          key={product.key}
          onClick={() => SeeProductDetails(product)}
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          xxl={8}
        >
          <AntCard style={{ cursor: 'pointer' }} shadow="true">
            <Image
              style={{ marginBottom: 10 }}
              src={product.avatar}
              height="300px"
            />
            <Text strong>{WalletFormatConversion(product.price)}</Text>

            <Text style={{ textOverflow: 'ellipsis' }}>
              {product.description}
            </Text>

            <Text kind="secondary">{`${product.city}, ${product.federalState}`}</Text>
          </AntCard>
        </Column>
      ))}
    </Row>
  ) : (
    <Empty
      style={{ background: 'transparent' }}
      description="Não há nenhum produto ou serviço ofertado no momento"
    />
  );
}

export default memo(ProductListItems);
