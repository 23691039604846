import React, { useContext } from 'react';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Affix from '~/components/affix';
import Drawer from '~/components/drawer';
import Text from '~/components/typography/text';

import FilterContainer from '~/pages/Marketplace/components/FilterContainer';
import ProductsContainer from '~/pages/Marketplace/components/ProductsContainer';
import ProductDetails from '~/pages/Marketplace/components/ProductDetails';

import { Body, Tag } from './style';

export default function Dashboard() {
  const params = useParams();

  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Body>
      {!isMobile && <FilterContainer theme={theme} />}

      <ProductsContainer />

      {isMobile && !params.productId && (
        <Drawer
          placement="left"
          title="Pesquisando no feira-livre"
          Trigger={
            <Affix offsetBottom={15}>
              <Tag backgroundColor={theme.color.backgroundPrimary}>
                <Text color={theme.color.textOnPrimaryBackground}>
                  Procurar
                </Text>
                <ShoppingCartOutlined
                  style={{
                    color: theme.color.textOnPrimaryBackground,
                    fontSize: 18,
                  }}
                />
              </Tag>
            </Affix>
          }
        >
          <FilterContainer />
        </Drawer>
      )}
      {params.productId && <ProductDetails />}
    </Body>
  );
}
