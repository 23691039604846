import React, { memo, useEffect, useState } from 'react';

import Title from '~/components/typography/title';

import Categoryitem from './components/CategoryItem';

import { Container } from './style';

import categories from '~/pages/Marketplace/categories';

function Categories() {
  const [activeCategories, setActiveCategories] = useState([]);

  useEffect(() => {
    async function PrepareCategories() {
      let activeCategories = [];
      for (const key in categories) {
        if (categories[key].isActive) {
          activeCategories.push(key);
        }
      }

      setActiveCategories(activeCategories);
    }
    PrepareCategories();
  }, []);

  return (
    <Container>
      <Title level={4}>Categorias</Title>
      {activeCategories.map((parentCategory) => (
        <Categoryitem
          key={parentCategory}
          category={categories[parentCategory]}
        />
      ))}
    </Container>
  );
}

export default memo(Categories);
