import styled from 'styled-components';

export const FilterContainerBody = styled.div`
  background-color: ${(props) => props.backgroundColor};
  box-shadow: ${(props) => props.boxShadow};
  display: flex;
  margin: 5px;
  flex-direction: column;
  border-radius: 8px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(props) => props.scrollBar};
  }
`;
