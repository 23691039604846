import styled from 'styled-components';

import { Collapse } from 'antd';

const { Panel } = Collapse;

export const AntCollapse = styled(Collapse)`
  background-color: ${(props) => props.backgroundColor};

  .ant-collapse-content-box {
    padding: 5px 0 !important;
  }
  .ant-collapse-item {
    border: none;
  }

  border: 1px solid ${(props) => props.borderColor};

  margin-bottom: 10px;
`;

export const AntPanel = styled(Panel)``;
