import React, { memo, useEffect } from 'react';

import { TileLayer, useMap } from 'react-leaflet';
import Leaflet from 'leaflet';

import Geocode from 'react-geocode';

import Map from './style';

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function MapDownward({ coordinates, zoom }) {
  const map = useMap();

  useEffect(() => {
    map.setView(coordinates, map.getZoom());
  }, [coordinates, map]);

  useEffect(() => {
    map.setZoom(zoom);
  }, [zoom, map]);

  return null;
}

function Maps({ coordinates, scrollWheelZoom, zoom, children }) {
  Geocode.setApiKey('AIzaSyDSvmdRoOEFI1OUp8tjbDw5GLSyuU9aT8U');
  Geocode.setRegion('pt-BR');
  Geocode.setLanguage('pt-BR');
  Geocode.setLocationType('ROOFTOP');

  return (
    <Map
      center={coordinates}
      scrollWheelZoom={scrollWheelZoom}
      zoom={zoom}
      zoomControl={false}
    >
      <MapDownward zoom={zoom} coordinates={coordinates} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </Map>
  );
}

export default memo(Maps);
