import React, { useContext, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { MenuFoldOutlined } from '@ant-design/icons';
import Animate, { FadeIn } from 'animate-css-styled-components';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import System from '~/environments/System';

import Text from '~/components/typography/text';
import Drawer from '~/components/drawer';
import { ExternalLink } from '~/components/link';
import Button from '~/components/button';
import Affix from '~/components/affix';
import Space from '~/components/space';

import { GetBrandIcon } from '~/utils/SocialMeAssets';

import {
  Container,
  Content,
  LeftItems,
  RightItems,
  LinkBox,
  ImageBox,
} from './style';

import MobileDrawer from './components/MobileDrawer';
import ModulesMenu from './components/ModulesMenu';

export default function MainNavbar({ navbarItems }) {
  const [closeDrawer, setCloseDrawer] = useState(false);
  const { pathname } = useLocation();

  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <Affix offsetTop={0}>
      <Container
        boxShadowColor={theme.color.borderColor}
        backgroundColor={theme.color.white}
      >
        <Content>
          {isMobile ? (
            <>
              <LeftItems>
                <Drawer
                  title="Menu"
                  placement="left"
                  closeDrawer={closeDrawer}
                  Trigger={
                    <Button
                      shape="circle"
                      icon={
                        <MenuFoldOutlined
                          onClick={() => setCloseDrawer(false)}
                          size={32}
                          style={{
                            color: theme.color.textOnSecondaryBackground,
                          }}
                        />
                      }
                    />
                  }
                  DrawerContentComponent={MobileDrawer}
                  DrawerContentComponentParams={{
                    setCloseDrawer,
                    navbarItems,
                    theme,
                  }}
                />
              </LeftItems>

              <Animate
                Animation={[FadeIn]}
                duration={['0.8s']}
                delay={['0.2s']}
              >
                <RightItems>
                  <Space direction="horizontal">
                    <Button type="primary">Entrar</Button>
                  </Space>
                </RightItems>
              </Animate>
            </>
          ) : (
            <>
              <LeftItems>
                <ImageBox
                  src={GetBrandIcon('socialMeVariation2')}
                  alt="SocialMe"
                />

                {navbarItems.map((navbarItem) => (
                  <LinkBox key={navbarItem.path}>
                    <Link to={navbarItem.path}>
                      <Text
                        style={{ fontSize: 16 }}
                        kind={
                          pathname === navbarItem.path ? 'primary' : 'secondary'
                        }
                      >
                        {navbarItem.title}
                      </Text>
                    </Link>
                  </LinkBox>
                ))}
              </LeftItems>
              <Animate
                Animation={[FadeIn]}
                duration={['0.8s']}
                delay={['0.2s']}
              >
                <RightItems>
                  <Space direction="horizontal">
                    <ModulesMenu isMobile={isMobile} />
                    <ExternalLink
                      to={`https://${System.hostname.authentication}`}
                    >
                      <Button type="primary">Entrar</Button>
                    </ExternalLink>
                  </Space>
                </RightItems>
              </Animate>
            </>
          )}
        </Content>
      </Container>
    </Affix>
  );
}
