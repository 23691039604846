import styled from 'styled-components';

import { Col } from 'antd';

import Card from '~/components/card';

import System from '~/environments/System';

export const AntCard = styled(Card)`
  min-height: 420;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const Column = styled(Col)`
  padding: 0 5px 5px 0;

  &:nth-child(3n) {
    padding: 0 0 5px 0;
  }

  @media (max-width: ${`${System.TabletBreakpoint}px`}) {
    padding: 0 0 5px 0;

    &:nth-child(odd) {
      padding: 0 5px 5px 0;
    }
  }

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    padding: 5px 0;
  }
`;
