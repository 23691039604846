import API from '~/environments/APIs';

const Geo = {
  Get: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/countryStateCities',
    method: 'post',
    timeout: '50000',
  },
  GetForSelect: {
    baseURL: API.socialMe,
    url: '/person/personId/countryStateCities',
    method: 'post',
    timeout: '50000',
  },
  Show: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/countryStateCity/countryStateCityId',
    method: 'post',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/countryStateCity',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/countryStateCity/countryStateCityId',
    method: 'put',
    timeout: '50000',
  },
  Delete: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/countryStateCity/countryStateCityId',
    method: 'delete',
    timeout: '50000',
  },
};

export default Geo;
