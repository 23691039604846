import styled from 'styled-components';

import { CloseCircleOutlined } from '@ant-design/icons';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
  border-bottom: 2px solid ${(props) => props.borderColor};
`;

export const CloseIcon = styled(CloseCircleOutlined)`
  font-size: 30px;
  margin: 10px 0;
  color: ${(props) => props.color};
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: ${(props) => props.hovercolor};
  }
`;
