import styled from 'styled-components';

const Image = styled.div`
  background-size: ${(props) => props.size};
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  min-height: ${(style) => `${style.height}`};
  width: ${(style) => `${style.width}`};
  position: relative;
  border-radius: 8px;
`;

export default Image;
