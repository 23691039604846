export default {
  Vehicle: {
    isActive: true,
    displayName: 'Veículos',
    name: 'vehicle',
    children: {
      Boats: {
        isActive: true,
        displayName: 'Barcos',
        name: 'boats',
      },
      Cars: {
        isActive: true,
        displayName: 'Carros',
        name: 'cars',
      },
      Motorcycles: {
        isActive: true,
        displayName: 'Motocicletas',
        name: 'motorcycles',
      },
      PowersportVehicles: {
        isActive: true,
        displayName: 'Veí­culos powersport',
        name: 'powersportVehicles',
      },
      PickupTrucks: {
        isActive: true,
        displayName: 'Caminhonetes',
        name: 'pickuptrucks',
      },
      Gipes: {
        isActive: true,
        displayName: 'Gipes',
        name: 'jeep',
      },
    },
  },
  MusicalInstruments: {
    isActive: true,
    displayName: 'Instrumentos musicais',
    name: 'MusicalInstruments',
    children: {
      WindInstrument: {
        isActive: true,
        displayName: 'Instrumento de sopro ',
        name: 'WindInstrument',
      },
      StringInstruments: {
        isActive: true,
        displayName: 'Instrumentos de cordas',
        name: 'StringInstruments',
      },

      PercussionInstruments: {
        isActive: true,
        displayName: 'Instrumentos de percussão',
        name: 'PercussionInstruments',
      },
    },
  },
  OfficeItems: {
    isActive: true,
    displayName: 'Artigos para escritório',
    name: 'OfficeItems',
    children: {
      OfficeFurniture: {
        isActive: true,
        displayName: 'Móveis de escritório',
        name: 'OfficeFurniture',
      },
      DeskOrganizers: {
        isActive: true,
        displayName: 'Organizadores de mesa',
        name: 'DeskOrganizers',
      },

      BubbleWrap: {
        isActive: true,
        displayName: 'Plástico bolha',
        name: 'BubbleWrap',
      },
      Clipboards: {
        isActive: true,
        displayName: 'Pranchetas',
        name: 'Clipboards',
      },

      Whiteboards: {
        isActive: true,
        displayName: 'Quadros brancos',
        name: 'Whiteboards',
      },
    },
  },

  SportiveArticles: {
    isActive: true,
    displayName: 'Artigos esportivos',
    name: 'SportiveArticles',
    children: {
      Bicycles: {
        isActive: true,
        displayName: 'Bicicletas',
        name: 'Bicycles',
      },
      Kayaks: {
        isActive: true,
        displayName: 'Caiaques',
        name: 'Kayaks',
      },

      GymAndExerciseEquipment: {
        isActive: true,
        displayName: 'Equipamento de ginástica e exercício',
        name: 'GymAndExerciseEquipment',
      },

      OutdoorRecreationEquipment: {
        isActive: true,
        displayName: 'Equipamento de recreação ao ar livre',
        name: 'OutdoorRecreationEquipment',
      },

      SportEquipment: {
        isActive: true,
        displayName: 'Equipamentos esportivos',
        name: 'SportEquipment',
      },
    },
  },
  Electronics: {
    isActive: true,
    displayName: 'Eletrônicos',
    name: 'electronics',
    children: {
      PhoneAccessories: {
        isActive: true,
        displayName: 'Acessórios para celular',
        name: 'PhoneAccessories',
      },
      Phone: {
        isActive: true,
        displayName: 'Celulares',
        name: 'phone',
      },

      Cameras: {
        isActive: true,
        displayName: 'Câmeras',
        name: 'Cameras',
      },
      SecurityCameras: {
        isActive: true,
        displayName: 'Câmeras de segurança',
        name: 'SecurityCameras',
      },
      Headphones: {
        isActive: true,
        displayName: 'Fones de ouvido',
        name: 'Headphones',
      },
      ElectronicComponentsForCars: {
        isActive: true,
        displayName: 'Componentes eletrônicos para carros',
        name: 'ElectronicComponentsForCars',
      },

      PortableAudioAndVideoEquipment: {
        isActive: true,
        displayName: 'Equipamentos portáteis de áudio e vídeo',
        name: 'PortableAudioAndVideoEquipment',
      },
      VideogameAndConsoles: {
        isActive: true,
        displayName: 'Videogames e consoles',
        name: 'VideogameAndConsoles',
      },
      TVs: {
        isActive: true,
        displayName: 'TVs',
        name: 'TVs',
      },

      HomeAudioandVideoSystems: {
        isActive: true,
        displayName: 'Sistemas domésticos de áudio e vídeo',
        name: 'HomeAudioandVideoSystems',
      },

      PrintersScannersAndFaxMachines: {
        isActive: true,
        displayName: 'Impressoras, scanners e aparelhos de fax',
        name: 'HomeAudioandVideoSystems',
      },

      UsedHomeAutomationSystems: {
        isActive: true,
        displayName: 'Sistemas de automação residencial usados',
        name: 'UsedHomeAutomationSystems',
      },
    },
  },
  ToysAndGames: {
    isActive: true,
    displayName: 'Brinquedos e jogos',
    name: 'toysandgames',
    children: {
      StuffedAnimals: {
        isActive: true,
        displayName: 'Bichos de pelúcia',
        name: 'stuffedanimals',
      },
      Dolls: {
        isActive: true,
        displayName: 'Bonecas',
        name: 'dolls',
      },
      ActionDolls: {
        isActive: true,
        displayName: 'Bonecos de ação',
        name: 'ActionDolls',
      },
      MakeBelieveToys: {
        isActive: true,
        displayName: 'Brinquedos de faz-de-conta',
        name: 'MakeBelieveToys',
      },
      AssemblingToys: {
        isActive: true,
        displayName: 'Brinquedos de montar',
        name: 'AssemblingToys',
      },
      MathToys: {
        isActive: true,
        displayName: 'Brinquedos de matemática',
        name: 'MathToys',
      },
      EducativeToys: {
        isActive: true,
        displayName: 'Brinquedos educativos',
        name: 'EducativeToys',
      },
      DollHouse: {
        isActive: true,
        displayName: 'Casa de boneca',
        name: 'DollHouse',
      },
      Games: {
        isActive: true,
        displayName: 'Jogos',
        name: 'Games',
      },
      Puzzle: {
        isActive: true,
        displayName: 'Quebra-cabeças',
        name: 'Puzzle',
      },
      ModelKits: {
        isActive: true,
        displayName: 'Kits de modelismo',
        name: 'ModelKits',
      },
      ToyVehicles: {
        isActive: true,
        displayName: 'Veículos de brinquedo',
        name: 'ToyVehicles',
      },
    },
  },
};
