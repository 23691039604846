export default {
  SMB89FCB3: {
    message: 'The product/service model was successfully deleted',
    //ProductOrService.Delete
  },
  SMC7A8AE7: {
    message: 'The activity type was successfully deleted',
    //Admin.ActivityType.Delete
  },
  SM0ACEA3C: {
    message: 'The address was successfully deleted',
  },
};
