import React, { useState } from 'react';

import Select from '~/components/select';
import Text from '~/components/typography/text';
import Search from '~/components/search';

export default function MapFilters({ setFilters }) {
  const [address, setAddress] = useState([]);

  const circleRadius = [
    {
      label: '1 quilômetro',
      value: 1,
    },
    {
      label: '2 quilômetro',
      value: 2,
    },
    {
      label: '3 quilômetro',
      value: 3,
    },
    {
      label: '4 quilômetro',
      value: 4,
    },
    {
      label: '5 quilômetro',
      value: 5,
    },
    {
      label: '6 quilômetro',
      value: 6,
    },
    {
      label: '7 quilômetro',
      value: 7,
    },
    {
      label: '8 quilômetro',
      value: 8,
    },
    {
      label: '9 quilômetro',
      value: 9,
    },
    {
      label: '10 quilômetro',
      value: 10,
    },
  ];

  return (
    <div>
      <Text>Localização</Text>
      <Search
        size="large"
        value={address}
        onChange={(event) => setAddress(event.target.value)}
        onSearch={(value) =>
          setFilters((oldFilters) => {
            return { ...oldFilters, location: value };
          })
        }
      />
      <Text style={{ marginTop: 10 }}>Raio</Text>
      <Select
        onChange={(value) =>
          setFilters((oldFilters) => {
            return { ...oldFilters, maxDistancie: value };
          })
        }
        size="large"
        options={circleRadius}
      />
    </div>
  );
}
