import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';
import { useHistory, useLocation } from 'react-router-dom';

import Text from '~/components/typography/text';

import { AntCollapse, AntPanel, TextBox } from './style';

import { SetNewQueryParameterWithoutRepeat } from '~/utils/Transformations';

function CategoryItem({ category }) {
  const history = useHistory();
  const location = useLocation();

  function GetQueryParameter(query) {
    const parameter = new URLSearchParams(location.search).get(query);

    return parameter || '';
  }

  const { theme } = useContext(ThemeContext);

  return (
    <AntCollapse
      parent={category.name === GetQueryParameter('categoryParent')}
      bordered={false}
      theme={theme}
      onChange={() => {
        SetNewQueryParameterWithoutRepeat(
          [
            {
              name: 'categoryParent',
              value: category.name,
            },
          ],
          history,
          location
        );
      }}
    >
      <AntPanel header={category.displayName}>
        {Object.entries(category.children).map((category) => (
          <TextBox
            key={category[1].displayName}
            onClick={() => {
              SetNewQueryParameterWithoutRepeat(
                [
                  {
                    name: 'categoryChildren',
                    value: category[1].name,
                  },
                ],
                history,
                location
              );
            }}
          >
            <Text>{category[1].displayName}</Text>
          </TextBox>
        ))}
      </AntPanel>
    </AntCollapse>
  );
}

export default memo(CategoryItem);
