import React, { memo } from 'react';

import Header from '../Header';
import GridImages from './components/GridImages';

import { Container } from './style';

function ProductImages({ product, theme, isMobile }) {
  return (
    <Container
      backgroundColor={theme.color.white}
      borderColor={theme.color.borderColor}
    >
      {!isMobile && <Header product={product} theme={theme} />}
      <GridImages product={product} theme={theme} isMobile={isMobile} />
    </Container>
  );
}

export default memo(ProductImages);
