import React, { memo } from 'react';

import System from '~/environments/System';

import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
import Card from '~/components/card';
import Button from '~/components/button';
import { ExternalLink } from '~/components/link';

import { ButtonContainer } from './style';

function CommercialCard({ title, description }) {
  return (
    <Card shadow="true" style={{ marginBottom: 5 }}>
      <Title level={3} strong>
        {title}
      </Title>
      <Text>{description}</Text>
      <ButtonContainer>
        <ExternalLink to={`https://${System.hostname.partner}`}>
          <Button type="primary">Entrar</Button>
        </ExternalLink>
      </ButtonContainer>
    </Card>
  );
}

export default memo(CommercialCard);
