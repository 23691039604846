import React, { memo } from 'react';

import Button from '~/components/button';

import BasicProductInfo from './components/BasicProductInfo';
import MoreInfo from './components/MoreInfo';
import Header from '../Header';
import SellerInfo from './components/SellerInfo';

import { Container, Divider, ButtonContainer } from './style';

function ProductInformation({ theme, isMobile, product }) {
  return (
    <Container
      scrollBarThumbColor={theme.color.scrollBar}
      scrollBarColor={theme.color.white}
      backgroundColor={theme.color.white}
    >
      {isMobile && <Header product={product} theme={theme} />}
      <BasicProductInfo product={product} theme={theme} />
      <Divider color={theme.color.white} />
      <MoreInfo product={product} theme={theme} />
      <Divider color={theme.color.borderColor} />
      <SellerInfo product={product} theme={theme} />

      <ButtonContainer backgroundColor={theme.color.white}>
        <Button style={{ width: '100%' }} size="large" type="primary">
          Solicitar compra
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default memo(ProductInformation);
