import React, { memo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Title from '~/components/typography/title';
import Search from '~/components/search';

import { SetNewQueryParameterWithoutRepeat } from '~/utils/Transformations';

import { Container, SearchIcon } from './style';

function FilterSearch() {
  const location = useLocation();
  const history = useHistory();

  function GetQueryParameter(query, defaultValue = '') {
    const parameter = new URLSearchParams(location.search).get(query);

    return parameter || defaultValue;
  }

  const [search, setSearch] = useState(GetQueryParameter('search'));

  return (
    <Container>
      <Title level={4}>Feira Livre</Title>
      <Search
        value={search}
        enterButton={<SearchIcon />}
        onChange={(event) => setSearch(event.target.value)}
        onSearch={(value) => {
          SetNewQueryParameterWithoutRepeat(
            [
              {
                name: 'search',
                value,
              },
            ],
            history,
            location
          );
        }}
      />
    </Container>
  );
}

export default memo(FilterSearch);
