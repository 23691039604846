import React, { useState, createContext, useContext, useEffect } from 'react';
// Libraries
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Context
import { BrowserContext } from './BrowserContext';
import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
import { LanguageContext } from './LanguageContext';
// Environments
import Debug from '~/environments/Debug';
// Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';

const isDebug = Debug.Context.Interface;

//! Procurar nos cookies, se tiver algum tema, seta a cor, senão coloca o light
//! Verificar se funciona mesmo não carregando a props.children

const Container = styled.div`
  background-color: ${(style) => style.backgroundColor || '#fff'};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${(style) => style.primary || '#fff'};
`;

export const InterfaceContext = createContext();

const InterfaceContextProvider = ({ children }) => {
  const { endedUserLogic } = useContext(AuthContext);
  const { endedBrowserLogic } = useContext(BrowserContext);
  const { theme } = useContext(ThemeContext);
  const { translate } = useContext(LanguageContext);

  const [loadInterface, setLoadInterface] = useState(true);
  const [message, setMessage] = useState(translate.messages.loading.text);
  const [showApology, setShowApology] = useState(false);

  useEffect(() => {
    if (endedBrowserLogic && endedUserLogic) {
      if (isDebug) console.log('Logics finished');
      setLoadInterface(false);
    }
  }, [endedBrowserLogic, endedUserLogic]);

  useEffect(() => {
    async function Slow() {
      if (loadInterface) {
        setMessage(translate.messages.almostThere.text);
      }
    }
    async function VerySlow() {
      if (loadInterface) {
        setMessage(translate.messages.itWasntSupposedToTakeThatLong.text);
      }
    }
    async function VeryVerySlow() {
      if (loadInterface) {
        setShowApology(true);
      }
    }
    setTimeout(function () {
      Slow();
    }, 3000);
    setTimeout(function () {
      VerySlow();
    }, 10000);
    setTimeout(function () {
      VeryVerySlow();
    }, 20000);
  }, [loadInterface, translate]);

  const ToggleLoadInterface = () => {
    setLoadInterface(!loadInterface);
  };

  return (
    <InterfaceContext.Provider value={{ loadInterface, ToggleLoadInterface }}>
      {loadInterface ? (
        <Container
          style={{
            backgroundColor:
              (theme && theme.color && theme.color.backgroundSecondary) ||
              '#fff',
            color: (theme && theme.color && theme.color.primary) || '#fff',
          }}
        >
          {showApology ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Title level={4}>
                {translate.messages.sorryForTheDelay.text}
              </Title>
              <Text>{translate.messages.tryAgainLater.text}</Text>
            </div>
          ) : (
            <Spin
              style={{
                color: (theme && theme.color && theme.color.primary) || '#fff',
              }}
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    color:
                      (theme && theme.color && theme.color.primary) || '#fff',
                  }}
                  spin
                />
              }
              tip={`${message}...`}
            />
          )}
        </Container>
      ) : (
        children
      )}
    </InterfaceContext.Provider>
  );
};

export default InterfaceContextProvider;
