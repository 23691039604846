import React, { memo, useContext } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import phoneA from '~/assets/images/phones/cel-1.jpg';
import phoneB from '~/assets/images/phones/cel-2.jpg';
import phoneC from '~/assets/images/phones/cel-3.jpg';
import phoneD from '~/assets/images/phones/cel-4.jpg';
import phoneE from '~/assets/images/phones/cel-5.jpg';
import phoneF from '~/assets/images/phones/cel-6.jpg';
import phoneG from '~/assets/images/phones/cel-7.jpg';
import phoneH from '~/assets/images/phones/cel-8.png';
import phoneJ from '~/assets/images/phones/cel-10.jpg';

import ProductImages from './components/ProductImages';
import ProductInformation from './components/ProductInformation';

import { Container } from './style';

function ProductDetails() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const product = {
    key: 1,
    images: [
      phoneA,
      phoneC,
      phoneD,
      phoneE,
      phoneF,
      phoneG,
      phoneH,
      phoneJ,
      phoneB,
    ],
    avatar: phoneB,
    price: '480000000',
    nameForSell: 'J7 PRO funcionando perfeitamente',
    in_stock: true,
    description: 'Vendo celular G6 play 32:GB de memória interna',
    parentCategory: 'Eletrônico',
    childCategory: 'Celulares Samsung',
    city: 'Boa vista',
    federalState: 'RR',
    more_details: {
      chip: 'Samsung Exynos 7884 Octa',
      condition: 'novo',
      memory_ram: '4 GB',
      internal_storage: '32 GB',
      processor: 'Octa-core (2x1.6 GHz & 6x1.35 GHz)',
      graphics_processor: 'ARM Mali-G71 MP2',
      memory_card: 'MicroSD de até 512 GB',
      rear_camera_resolution: '13 MP',
      front_camera_resolution: '5 MP',
      flash: 'LED',
      video: '1080p 30fps',
    },
  };

  return (
    <Container>
      {isMobile ? (
        <>
          <ProductInformation
            product={product}
            theme={theme}
            isMobile={isMobile}
          />
          <ProductImages product={product} theme={theme} isMobile={isMobile} />
        </>
      ) : (
        <>
          <ProductImages product={product} theme={theme} isMobile={isMobile} />
          <ProductInformation
            product={product}
            theme={theme}
            isMobile={isMobile}
          />
        </>
      )}
    </Container>
  );
}

export default memo(ProductDetails);
