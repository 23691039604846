import React, { useContext, memo } from 'react';

import { ThemeContext } from '~/contexts/ThemeContext';

import MainLink from './style';

function Link({ to, color, children }) {
  const { theme } = useContext(ThemeContext);

  return (
    <MainLink
      color={theme.color.textOnSecondaryBackground || color}
      hovercolor={theme.color.primary}
      to={to}
    >
      {children}
    </MainLink>
  );
}

export default memo(Link);
