import React, { useContext, memo } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Standalone Components
import { MainTitle } from './style';

function AntTitle({ children, kind, level, color, style }) {
  const { theme } = useContext(ThemeContext);

  function SetColor() {
    if (color) return color;
    switch (kind) {
      case 'primary':
        return theme.color.primary;
      case 'secondary':
        return 'rgba(0, 0, 0, .45)';
      default:
        break;
    }
  }

  return (
    <MainTitle level={level} color={SetColor()} style={style}>
      {children}
    </MainTitle>
  );
}

export default memo(AntTitle);
