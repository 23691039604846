import React, { memo, useContext } from 'react';
// Standalone Components
import { MainCard } from './style';

import { ThemeContext } from '~/contexts/ThemeContext';

function AntCard({ children, bordered, bodyStyle, shadow, style }) {
  const { theme } = useContext(ThemeContext);

  return (
    <MainCard
      shadow={shadow}
      boxshadow={theme.color.cardShadowBox}
      bordered={bordered}
      bodyStyle={bodyStyle}
      style={style}
    >
      {children}
    </MainCard>
  );
}

export default memo(AntCard);
