export function CapitalizeString(value) {
  const stringLowerCase = value.toLowerCase();
  let array = stringLowerCase.split(' ');
  let newArray = [];

  for (var index = 0; index < array.length; index++) {
    newArray.push(array[index].charAt(0).toUpperCase() + array[index].slice(1));
  }

  return newArray.join(' ');
}

export function SetNewQueryParameterWithoutRepeat(
  pametersWithValues,
  history,
  location
) {
  let url = `${location.pathname}${location.search}`;

  pametersWithValues.forEach((urlQuery) => {
    const { name, value } = urlQuery;

    if (location.search.indexOf(name) === -1) {
      url += `${url.includes('?') ? '&' : '?'}${name}=${value}`;
    } else {
      const oldQueryValue = new URLSearchParams(location.search).get(name);

      url = url.replace(`${name}=${oldQueryValue}`, `${name}=${value}`);
    }
  });

  history.push(url);
}

export function WalletFormatConversion(value) {
  const division = value / 1000000;

  if (division % 1 === 0) {
    const newValue = `R$${division.toLocaleString('pt-br')},00`;
    return newValue;
  } else {
    const newValue = division.toFixed(2);
    return newValue.replace('.', ',');
  }
}

export const RemoveWhatIsNotNumber = (value) => {
  return value.replace(/[^\d]+/g, '');
};
