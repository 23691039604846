import React, { useState, useEffect, useContext } from 'react';

import Geocode from 'react-geocode';
import { message } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';

import MapContainer from './components/MapContainer';
import MapFilters from './components/MapFilters';

import Loading from '~/components/loading';
import Modal from '~/components/modal';
import Button from '~/components/button';
import Text from '~/components/typography/text';

import { Box } from './style';

export default function SearchByMaps() {
  const [coordinates, setCoordinates] = useState([0, 0]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    maxDistancie: 1,
    location: '',
  });

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setLoading(true);

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(position) {
      const { coords } = position;

      setCoordinates([coords.latitude, coords.longitude]);

      setLoading(false);
    }

    function error(err) {}

    navigator.geolocation.getCurrentPosition(success, error, options);
  }, []);

  useEffect(() => {
    if (filters.location !== '') {
      Geocode.fromAddress(filters.location).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setCoordinates([lat, lng]);
        },
        (error) => {
          message.error('Endereço não encontrado.');
        }
      );
    }
  }, [filters]);

  return (
    <>
      <Modal
        title="Alterar localização"
        footer={<Button type="primary">Alterar</Button>}
        Trigger={
          <Box
            hoverBackgroundColor={theme.color.primary}
            hoverColor={theme.color.white}
          >
            <Text
              style={{ fontSize: 16 }}
            >{`Boa vista - No raio de ${filters.maxDistancie} quilômetro(s)`}</Text>
          </Box>
        }
      >
        <>
          <MapFilters setFilters={setFilters} />

          {loading ? (
            <Loading text="Carregando o mapa" />
          ) : (
            <MapContainer
              coordinates={coordinates}
              maxDistancie={filters.maxDistancie}
            />
          )}
        </>
      </Modal>
    </>
  );
}
