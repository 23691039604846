import API from '~/environments/APIs';

const SystemOperations = {
  Get: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/systemOperations',
    method: 'post',
    timeout: '50000',
  },
  Add: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/systemOperation',
    method: 'post',
    timeout: '50000',
  },
  Edit: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/systemOperation/systemOperationId',
    method: 'put',
    timeout: '50000',
  },
  Delete: {
    baseURL: API.socialMe,
    url: '/manage/person/personId/systemOperation/systemOperationId',
    method: 'delete',
    timeout: '50000',
  },
};

export default SystemOperations;
