import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
`;

export const FlexColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;
