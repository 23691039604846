export default {
  SM00EF13A: {
    message: 'Browser token successfully created',
  },
  SM9DC07A7: {
    message: 'Successfully registered person',
  },
  SMC463647: {
    message: 'The product/service model was successfully created',
    //ProductOrService.Add
  },
  SM0CDE9E7: {
    message: 'The activity type was successfully created',
    //Admin.ActivityType.Add
  },
  SM927FEDF: {
    message: 'The address was successfully created',
  },
  SM81A593E: {
    message: "The 'operation intent' was successfully created",
  },
};
