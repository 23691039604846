import React, { useState, useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Radio } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';

import { AntCollapse, AntPanel } from './style';

import { SetNewQueryParameterWithoutRepeat } from '~/utils/Transformations';

export default function Condition() {
  const location = useLocation();
  const history = useHistory();

  function GetQueryParameter(query, defaultValue = '') {
    const parameter = new URLSearchParams(location.search).get(query);

    return parameter || defaultValue;
  }

  const [value, setValue] = useState(GetQueryParameter('condition', 'all'));
  const [isActive, setIsActive] = useState(false);

  const { theme } = useContext(ThemeContext);

  const radioStyle = {
    display: 'block',
    padding: '10px 35px',
  };

  const onChange = (event) => {
    const { value } = event.target;
    setValue(value);
    SetNewQueryParameterWithoutRepeat(
      [
        {
          name: 'condition',
          value,
        },
      ],
      history,
      location
    );
  };

  return (
    <AntCollapse
      onChange={() => setIsActive(!isActive)}
      backgroundColor={theme.color.white}
      borderColor={isActive ? theme.color.primary : theme.color.borderColor}
      bordered={false}
    >
      <AntPanel header="Condição do produto">
        <Radio.Group onChange={onChange} value={value}>
          <Radio style={radioStyle} value="all">
            Tudo
          </Radio>
          <Radio style={radioStyle} value="new">
            Novo
          </Radio>
          <Radio style={radioStyle} value="used">
            Usado
          </Radio>
        </Radio.Group>
      </AntPanel>
    </AntCollapse>
  );
}
