import API from '~/environments/APIs';

const System = {
  Iam: {
    God: {
      baseURL: API.socialMe,
      url: '/god/amI',
      method: 'get',
      timeout: '50000',
    },
    Admin: {
      baseURL: API.socialMe,
      url: '/manage/amI',
      method: 'get',
      timeout: '50000',
    },
  },
  Disabilities: {
    GetAll: {
      baseURL: API.socialMe,
      url: '/person/personId/disabilities',
      method: 'post',
      timeout: '50000',
    },
  },
  MeasurementUnit: {
    GetAll: {
      baseURL: API.socialMe,
      url: '/person/personId/mUnities',
      method: 'post',
      timeout: '50000',
    },
  },
};

export default System;
