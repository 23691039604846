export default {
  authentication: {
    original: 'Account',
    text: 'Conta',
  },
  accountManagement: {
    original: 'Profile',
    text: 'Perfil',
  },
  beneficiary: {
    original: 'Beneficiary',
    text: 'Beneficiário',
  },
  donor: {
    original: 'Donor',
    text: 'Doador',
  },
  partner: {
    original: 'Partner',
    text: 'Parceiro',
  },
  manager: {
    original: 'Manager',
    text: 'Gestor',
  },
  digitalWallet: {
    original: 'Wallet',
    text: 'Carteira',
  },
  administration: {
    original: 'Administration',
    text: 'Administração',
  },
  paymentGateway: {
    original: 'Payments',
    text: 'Pagamentos',
  },
  telephoneOperator: {
    original: 'TelephoneOperator',
    text: 'Me Liga',
  },
  operationsFinder: {
    original: 'OperationsFinder',
    text: 'Achei.me',
  },
  donationsPortal: {
    original: 'DonationsPortal',
    text: 'Doações',
  },
  marketplace: {
    original: 'Marketplace',
    text: 'Feira livre',
  },
  ecoop: {
    original: 'Ecoop',
    text: 'Ecoop',
  },
  citizen: {
    original: 'Citizen',
    text: 'Cidadão',
  },
  medicalRecord: {
    original: 'MedicalRecord',
    text: 'Prontuário',
  },
  socialMeModules: {
    original: 'SocialMe Modules',
    text: 'Módulos SocialMe',
  },
};
