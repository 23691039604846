import styled from 'styled-components';

import System from '~/environments/System';

import AntDivider from '~/components/divider';

export const Container = styled.div`
  width: 30%;
  height: 102vh;
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: ${`${System.TabletBreakpoint}px`}) {
    width: 35%;
  }

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    padding: 0 10px;
    height: auto;
    overflow-y: hidden;
    min-height: 100vh;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${(props) => props.scrollBarColor};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(props) => props.scrollBarThumbColor};
  }
`;

export const Divider = styled(AntDivider)`
  border-color: ${(props) => props.color};
`;

export const ButtonContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};

  margin-top: 20px;

  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
