import React, { useState, useContext, useEffect } from 'react';

import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';

import Text from '~/components/typography/text';

import { Header, Content } from './style';

export default function MyDrawer({
  placement,
  title,
  footer,
  closable,
  headerStyle,
  bodyStyle,
  Trigger,
  DrawerContentComponent,
  DrawerContentComponentParams,
  width,
  widthMobile,
  closeDrawer,
  onClose,
  keyboard,
  children,
}) {
  const [drawerVisibility, setDrawerVisibility] = useState(false);

  const { isTablet, isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  function OnClose() {
    if (onClose) return onClose();
    setDrawerVisibility(false);
  }
  function SetWidth() {
    if (widthMobile) return widthMobile;

    if (width) {
      return width;
    }

    if (isMobile) {
      return '100vw';
    }

    return '35vw';
  }

  useEffect(() => {
    if (closeDrawer === true && drawerVisibility === true) {
      setDrawerVisibility(false);
    }
  }, [closeDrawer, drawerVisibility]);

  const HeaderDesktop = () => {
    return (
      <>
        <Content width="90%">
          <Text
            strong
            fontSize={16}
            color={theme.color.textOnSecondaryBackground}
          >
            {title || ''}
          </Text>
        </Content>

        <Content width="10%">
          <ArrowRightOutlined
            onClick={() => OnClose()}
            color={theme.color.textOnSecondaryBackground}
            size={32}
          />
        </Content>
      </>
    );
  };

  const HeaderMobile = () => {
    return (
      <>
        <Content width="10%">
          <ArrowLeftOutlined
            onClick={() => OnClose()}
            color={theme.color.textOnSecondaryBackground}
            size={32}
          />
        </Content>
        <Content width="90%">
          <Text
            strong
            fontSize={14}
            color={theme.color.textOnSecondaryBackground}
          >
            {title || ''}
          </Text>
        </Content>
      </>
    );
  };

  const titleAtr = title
    ? {
        title: (
          <Header>{isTablet ? <HeaderMobile /> : <HeaderDesktop />}</Header>
        ),
      }
    : {};

  return (
    <>
      <div onClick={() => setDrawerVisibility(true)}>{Trigger}</div>
      <Drawer
        {...titleAtr}
        width={SetWidth()}
        footer={footer || ''}
        closable={closable || false}
        placement={placement}
        visible={drawerVisibility}
        headerStyle={
          headerStyle || {
            padding: 0,
            margin: 0,
          }
        }
        bodyStyle={bodyStyle || {}}
        onClose={onClose}
        keyboard={keyboard}
      >
        {children}
        {DrawerContentComponent && (
          <DrawerContentComponent propertys={DrawerContentComponentParams} />
        )}
      </Drawer>
    </>
  );
}
