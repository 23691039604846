import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Text from '~/components/typography/text';
import InputNumber from '~/components/inputNumber';

import { InputContainer, FlexColumn } from './style';

import { SetNewQueryParameterWithoutRepeat } from '~/utils/Transformations';

export default function MaxPrice() {
  const location = useLocation();
  const history = useHistory();

  function GetQueryParameter(query, defaultValue = '') {
    const parameter = new URLSearchParams(location.search).get(query);

    return parameter || defaultValue;
  }

  const [min, setMin] = useState(GetQueryParameter('min', 0));
  const [max, setMax] = useState(GetQueryParameter('max', 10000000));

  function OrganizeMaxAndMinValueCorrectly() {
    if (max < min) {
      setMin(max);
      setMax(min);
      SetNewQueryParameterWithoutRepeat(
        [
          {
            name: 'min',
            value: max,
          },
          {
            name: 'max',
            value: min,
          },
        ],
        history,
        location
      );
    }
  }

  const SetPriceValueOnQueryParameter = (value, setValue, query) => {
    setValue(value);

    console.log(`value`, value);
    console.log(`query`, query);

    SetNewQueryParameterWithoutRepeat(
      [
        {
          name: query,
          value,
        },
      ],
      history,
      location
    );
  };

  return (
    <InputContainer>
      <FlexColumn>
        <Text>Preço mínimo</Text>
        <InputNumber
          onBlur={OrganizeMaxAndMinValueCorrectly}
          style={{ width: '150px' }}
          value={min}
          min={0}
          max={10000000}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          onChange={(value) =>
            SetPriceValueOnQueryParameter(value, setMin, 'min')
          }
        />
      </FlexColumn>

      <FlexColumn>
        <Text>Preço máximo</Text>
        <InputNumber
          style={{ width: '150px' }}
          onBlur={OrganizeMaxAndMinValueCorrectly}
          value={max}
          min={0}
          max={10000000}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          onChange={(value) =>
            SetPriceValueOnQueryParameter(value, setMax, 'max')
          }
        />
      </FlexColumn>
    </InputContainer>
  );
}
